/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.bootstrap-iso :root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.bootstrap-iso *,
.bootstrap-iso *::before,
.bootstrap-iso *::after {
  box-sizing: border-box;
}

.bootstrap-iso html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.bootstrap-iso article, .bootstrap-iso aside, .bootstrap-iso figcaption, .bootstrap-iso figure, .bootstrap-iso footer, .bootstrap-iso header, .bootstrap-iso hgroup, .bootstrap-iso main, .bootstrap-iso nav, .bootstrap-iso section {
  display: block;
}

.bootstrap-iso body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

.bootstrap-iso [tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

.bootstrap-iso hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.bootstrap-iso h1, .bootstrap-iso h2, .bootstrap-iso h3, .bootstrap-iso h4, .bootstrap-iso h5, .bootstrap-iso h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.bootstrap-iso p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.bootstrap-iso abbr[title],
.bootstrap-iso abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

.bootstrap-iso address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

.bootstrap-iso ol,
.bootstrap-iso ul,
.bootstrap-iso dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.bootstrap-iso ol ol,
.bootstrap-iso ul ul,
.bootstrap-iso ol ul,
.bootstrap-iso ul ol {
  margin-bottom: 0;
}

.bootstrap-iso dt {
  font-weight: 700;
}

.bootstrap-iso dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

.bootstrap-iso blockquote {
  margin: 0 0 1rem;
}

.bootstrap-iso b,
.bootstrap-iso strong {
  font-weight: bolder;
}

.bootstrap-iso small {
  font-size: 80%;
}

.bootstrap-iso sub,
.bootstrap-iso sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.bootstrap-iso sub {
  bottom: -.25em;
}

.bootstrap-iso sup {
  top: -.5em;
}

.bootstrap-iso a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.bootstrap-iso a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.bootstrap-iso a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

.bootstrap-iso a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

.bootstrap-iso pre,
.bootstrap-iso code,
.bootstrap-iso kbd,
.bootstrap-iso samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

.bootstrap-iso pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

.bootstrap-iso figure {
  margin: 0 0 1rem;
}

.bootstrap-iso img {
  vertical-align: middle;
  border-style: none;
}

.bootstrap-iso svg {
  overflow: hidden;
  vertical-align: middle;
}

.bootstrap-iso table {
  border-collapse: collapse;
}

.bootstrap-iso caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

.bootstrap-iso th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

.bootstrap-iso label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.bootstrap-iso button {
  border-radius: 0;
}

.bootstrap-iso button:focus:not(:focus-visible) {
  outline: 0;
}

.bootstrap-iso input,
.bootstrap-iso button,
.bootstrap-iso select,
.bootstrap-iso optgroup,
.bootstrap-iso textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.bootstrap-iso button,
.bootstrap-iso input {
  overflow: visible;
}

.bootstrap-iso button,
.bootstrap-iso select {
  text-transform: none;
}

.bootstrap-iso [role="button"] {
  cursor: pointer;
}

.bootstrap-iso select {
  word-wrap: normal;
}

.bootstrap-iso button,
.bootstrap-iso [type="button"],
.bootstrap-iso [type="reset"],
.bootstrap-iso [type="submit"] {
  -webkit-appearance: button;
}

.bootstrap-iso button:not(:disabled),
.bootstrap-iso [type="button"]:not(:disabled),
.bootstrap-iso [type="reset"]:not(:disabled),
.bootstrap-iso [type="submit"]:not(:disabled) {
  cursor: pointer;
}

.bootstrap-iso button::-moz-focus-inner,
.bootstrap-iso [type="button"]::-moz-focus-inner,
.bootstrap-iso [type="reset"]::-moz-focus-inner,
.bootstrap-iso [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

.bootstrap-iso input[type="radio"],
.bootstrap-iso input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

.bootstrap-iso textarea {
  overflow: auto;
  resize: vertical;
}

.bootstrap-iso fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.bootstrap-iso legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

.bootstrap-iso progress {
  vertical-align: baseline;
}

.bootstrap-iso [type="number"]::-webkit-inner-spin-button,
.bootstrap-iso [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

.bootstrap-iso [type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

.bootstrap-iso [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.bootstrap-iso ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

.bootstrap-iso output {
  display: inline-block;
}

.bootstrap-iso summary {
  display: list-item;
  cursor: pointer;
}

.bootstrap-iso template {
  display: none;
}

.bootstrap-iso [hidden] {
  display: none !important;
}

.bootstrap-iso h1, .bootstrap-iso h2, .bootstrap-iso h3, .bootstrap-iso h4, .bootstrap-iso h5, .bootstrap-iso h6,
.bootstrap-iso .h1, .bootstrap-iso .h2, .bootstrap-iso .h3, .bootstrap-iso .h4, .bootstrap-iso .h5, .bootstrap-iso .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.bootstrap-iso h1, .bootstrap-iso .h1 {
  font-size: 2.5rem;
}

.bootstrap-iso h2, .bootstrap-iso .h2 {
  font-size: 2rem;
}

.bootstrap-iso h3, .bootstrap-iso .h3 {
  font-size: 1.75rem;
}

.bootstrap-iso h4, .bootstrap-iso .h4 {
  font-size: 1.5rem;
}

.bootstrap-iso h5, .bootstrap-iso .h5 {
  font-size: 1.25rem;
}

.bootstrap-iso h6, .bootstrap-iso .h6 {
  font-size: 1rem;
}

.bootstrap-iso .lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.bootstrap-iso .display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.bootstrap-iso .display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.bootstrap-iso .display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.bootstrap-iso .display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.bootstrap-iso hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.bootstrap-iso small,
.bootstrap-iso .small {
  font-size: 0.875em;
  font-weight: 400;
}

.bootstrap-iso mark,
.bootstrap-iso .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.bootstrap-iso .list-unstyled {
  padding-left: 0;
  list-style: none;
}

.bootstrap-iso .list-inline {
  padding-left: 0;
  list-style: none;
}

.bootstrap-iso .list-inline-item {
  display: inline-block;
}

.bootstrap-iso .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.bootstrap-iso .initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.bootstrap-iso .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.bootstrap-iso .blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}

.bootstrap-iso .blockquote-footer::before {
  content: "\2014\00A0";
}

.bootstrap-iso .img-fluid {
  max-width: 100%;
  height: auto;
}

.bootstrap-iso .img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.bootstrap-iso .figure {
  display: inline-block;
}

.bootstrap-iso .figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.bootstrap-iso .figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.bootstrap-iso code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > .bootstrap-iso code {
  color: inherit;
}

.bootstrap-iso kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

.bootstrap-iso kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

.bootstrap-iso pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

.bootstrap-iso pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.bootstrap-iso .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.bootstrap-iso .container,
.bootstrap-iso .container-fluid,
.bootstrap-iso .container-sm,
.bootstrap-iso .container-md,
.bootstrap-iso .container-lg,
.bootstrap-iso .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .bootstrap-iso .container, .bootstrap-iso .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .bootstrap-iso .container, .bootstrap-iso .container-sm, .bootstrap-iso .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .bootstrap-iso .container, .bootstrap-iso .container-sm, .bootstrap-iso .container-md, .bootstrap-iso .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .bootstrap-iso .container, .bootstrap-iso .container-sm, .bootstrap-iso .container-md, .bootstrap-iso .container-lg, .bootstrap-iso .container-xl {
    max-width: 1140px;
  }
}

.bootstrap-iso .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.bootstrap-iso .no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.bootstrap-iso .no-gutters > .col,
.bootstrap-iso .no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.bootstrap-iso .col-1, .bootstrap-iso .col-2, .bootstrap-iso .col-3, .bootstrap-iso .col-4, .bootstrap-iso .col-5, .bootstrap-iso .col-6, .bootstrap-iso .col-7, .bootstrap-iso .col-8, .bootstrap-iso .col-9, .bootstrap-iso .col-10, .bootstrap-iso .col-11, .bootstrap-iso .col-12, .bootstrap-iso .col,
.bootstrap-iso .col-auto, .bootstrap-iso .col-sm-1, .bootstrap-iso .col-sm-2, .bootstrap-iso .col-sm-3, .bootstrap-iso .col-sm-4, .bootstrap-iso .col-sm-5, .bootstrap-iso .col-sm-6, .bootstrap-iso .col-sm-7, .bootstrap-iso .col-sm-8, .bootstrap-iso .col-sm-9, .bootstrap-iso .col-sm-10, .bootstrap-iso .col-sm-11, .bootstrap-iso .col-sm-12, .bootstrap-iso .col-sm,
.bootstrap-iso .col-sm-auto, .bootstrap-iso .col-md-1, .bootstrap-iso .col-md-2, .bootstrap-iso .col-md-3, .bootstrap-iso .col-md-4, .bootstrap-iso .col-md-5, .bootstrap-iso .col-md-6, .bootstrap-iso .col-md-7, .bootstrap-iso .col-md-8, .bootstrap-iso .col-md-9, .bootstrap-iso .col-md-10, .bootstrap-iso .col-md-11, .bootstrap-iso .col-md-12, .bootstrap-iso .col-md,
.bootstrap-iso .col-md-auto, .bootstrap-iso .col-lg-1, .bootstrap-iso .col-lg-2, .bootstrap-iso .col-lg-3, .bootstrap-iso .col-lg-4, .bootstrap-iso .col-lg-5, .bootstrap-iso .col-lg-6, .bootstrap-iso .col-lg-7, .bootstrap-iso .col-lg-8, .bootstrap-iso .col-lg-9, .bootstrap-iso .col-lg-10, .bootstrap-iso .col-lg-11, .bootstrap-iso .col-lg-12, .bootstrap-iso .col-lg,
.bootstrap-iso .col-lg-auto, .bootstrap-iso .col-xl-1, .bootstrap-iso .col-xl-2, .bootstrap-iso .col-xl-3, .bootstrap-iso .col-xl-4, .bootstrap-iso .col-xl-5, .bootstrap-iso .col-xl-6, .bootstrap-iso .col-xl-7, .bootstrap-iso .col-xl-8, .bootstrap-iso .col-xl-9, .bootstrap-iso .col-xl-10, .bootstrap-iso .col-xl-11, .bootstrap-iso .col-xl-12, .bootstrap-iso .col-xl,
.bootstrap-iso .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.bootstrap-iso .col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.bootstrap-iso .row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.bootstrap-iso .row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.bootstrap-iso .row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.bootstrap-iso .row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.bootstrap-iso .row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.bootstrap-iso .row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.bootstrap-iso .col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.bootstrap-iso .col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.bootstrap-iso .col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.bootstrap-iso .col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.bootstrap-iso .col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.bootstrap-iso .col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.bootstrap-iso .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.bootstrap-iso .col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.bootstrap-iso .col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.bootstrap-iso .col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.bootstrap-iso .col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.bootstrap-iso .col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.bootstrap-iso .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.bootstrap-iso .order-first {
  order: -1;
}

.bootstrap-iso .order-last {
  order: 13;
}

.bootstrap-iso .order-0 {
  order: 0;
}

.bootstrap-iso .order-1 {
  order: 1;
}

.bootstrap-iso .order-2 {
  order: 2;
}

.bootstrap-iso .order-3 {
  order: 3;
}

.bootstrap-iso .order-4 {
  order: 4;
}

.bootstrap-iso .order-5 {
  order: 5;
}

.bootstrap-iso .order-6 {
  order: 6;
}

.bootstrap-iso .order-7 {
  order: 7;
}

.bootstrap-iso .order-8 {
  order: 8;
}

.bootstrap-iso .order-9 {
  order: 9;
}

.bootstrap-iso .order-10 {
  order: 10;
}

.bootstrap-iso .order-11 {
  order: 11;
}

.bootstrap-iso .order-12 {
  order: 12;
}

.bootstrap-iso .offset-1 {
  margin-left: 8.333333%;
}

.bootstrap-iso .offset-2 {
  margin-left: 16.666667%;
}

.bootstrap-iso .offset-3 {
  margin-left: 25%;
}

.bootstrap-iso .offset-4 {
  margin-left: 33.333333%;
}

.bootstrap-iso .offset-5 {
  margin-left: 41.666667%;
}

.bootstrap-iso .offset-6 {
  margin-left: 50%;
}

.bootstrap-iso .offset-7 {
  margin-left: 58.333333%;
}

.bootstrap-iso .offset-8 {
  margin-left: 66.666667%;
}

.bootstrap-iso .offset-9 {
  margin-left: 75%;
}

.bootstrap-iso .offset-10 {
  margin-left: 83.333333%;
}

.bootstrap-iso .offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .bootstrap-iso .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .bootstrap-iso .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-iso .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-iso .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .bootstrap-iso .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-iso .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bootstrap-iso .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .bootstrap-iso .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bootstrap-iso .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .bootstrap-iso .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .bootstrap-iso .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-iso .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .bootstrap-iso .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .bootstrap-iso .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-iso .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .bootstrap-iso .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .bootstrap-iso .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bootstrap-iso .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .bootstrap-iso .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .bootstrap-iso .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-iso .order-sm-first {
    order: -1;
  }
  .bootstrap-iso .order-sm-last {
    order: 13;
  }
  .bootstrap-iso .order-sm-0 {
    order: 0;
  }
  .bootstrap-iso .order-sm-1 {
    order: 1;
  }
  .bootstrap-iso .order-sm-2 {
    order: 2;
  }
  .bootstrap-iso .order-sm-3 {
    order: 3;
  }
  .bootstrap-iso .order-sm-4 {
    order: 4;
  }
  .bootstrap-iso .order-sm-5 {
    order: 5;
  }
  .bootstrap-iso .order-sm-6 {
    order: 6;
  }
  .bootstrap-iso .order-sm-7 {
    order: 7;
  }
  .bootstrap-iso .order-sm-8 {
    order: 8;
  }
  .bootstrap-iso .order-sm-9 {
    order: 9;
  }
  .bootstrap-iso .order-sm-10 {
    order: 10;
  }
  .bootstrap-iso .order-sm-11 {
    order: 11;
  }
  .bootstrap-iso .order-sm-12 {
    order: 12;
  }
  .bootstrap-iso .offset-sm-0 {
    margin-left: 0;
  }
  .bootstrap-iso .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .bootstrap-iso .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .bootstrap-iso .offset-sm-3 {
    margin-left: 25%;
  }
  .bootstrap-iso .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .bootstrap-iso .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .bootstrap-iso .offset-sm-6 {
    margin-left: 50%;
  }
  .bootstrap-iso .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .bootstrap-iso .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .bootstrap-iso .offset-sm-9 {
    margin-left: 75%;
  }
  .bootstrap-iso .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .bootstrap-iso .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .bootstrap-iso .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .bootstrap-iso .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-iso .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-iso .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .bootstrap-iso .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-iso .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bootstrap-iso .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .bootstrap-iso .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bootstrap-iso .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .bootstrap-iso .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .bootstrap-iso .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-iso .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .bootstrap-iso .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .bootstrap-iso .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-iso .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .bootstrap-iso .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .bootstrap-iso .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bootstrap-iso .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .bootstrap-iso .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .bootstrap-iso .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-iso .order-md-first {
    order: -1;
  }
  .bootstrap-iso .order-md-last {
    order: 13;
  }
  .bootstrap-iso .order-md-0 {
    order: 0;
  }
  .bootstrap-iso .order-md-1 {
    order: 1;
  }
  .bootstrap-iso .order-md-2 {
    order: 2;
  }
  .bootstrap-iso .order-md-3 {
    order: 3;
  }
  .bootstrap-iso .order-md-4 {
    order: 4;
  }
  .bootstrap-iso .order-md-5 {
    order: 5;
  }
  .bootstrap-iso .order-md-6 {
    order: 6;
  }
  .bootstrap-iso .order-md-7 {
    order: 7;
  }
  .bootstrap-iso .order-md-8 {
    order: 8;
  }
  .bootstrap-iso .order-md-9 {
    order: 9;
  }
  .bootstrap-iso .order-md-10 {
    order: 10;
  }
  .bootstrap-iso .order-md-11 {
    order: 11;
  }
  .bootstrap-iso .order-md-12 {
    order: 12;
  }
  .bootstrap-iso .offset-md-0 {
    margin-left: 0;
  }
  .bootstrap-iso .offset-md-1 {
    margin-left: 8.333333%;
  }
  .bootstrap-iso .offset-md-2 {
    margin-left: 16.666667%;
  }
  .bootstrap-iso .offset-md-3 {
    margin-left: 25%;
  }
  .bootstrap-iso .offset-md-4 {
    margin-left: 33.333333%;
  }
  .bootstrap-iso .offset-md-5 {
    margin-left: 41.666667%;
  }
  .bootstrap-iso .offset-md-6 {
    margin-left: 50%;
  }
  .bootstrap-iso .offset-md-7 {
    margin-left: 58.333333%;
  }
  .bootstrap-iso .offset-md-8 {
    margin-left: 66.666667%;
  }
  .bootstrap-iso .offset-md-9 {
    margin-left: 75%;
  }
  .bootstrap-iso .offset-md-10 {
    margin-left: 83.333333%;
  }
  .bootstrap-iso .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .bootstrap-iso .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .bootstrap-iso .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-iso .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-iso .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .bootstrap-iso .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-iso .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bootstrap-iso .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .bootstrap-iso .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bootstrap-iso .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .bootstrap-iso .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .bootstrap-iso .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-iso .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .bootstrap-iso .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .bootstrap-iso .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-iso .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .bootstrap-iso .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .bootstrap-iso .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bootstrap-iso .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .bootstrap-iso .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .bootstrap-iso .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-iso .order-lg-first {
    order: -1;
  }
  .bootstrap-iso .order-lg-last {
    order: 13;
  }
  .bootstrap-iso .order-lg-0 {
    order: 0;
  }
  .bootstrap-iso .order-lg-1 {
    order: 1;
  }
  .bootstrap-iso .order-lg-2 {
    order: 2;
  }
  .bootstrap-iso .order-lg-3 {
    order: 3;
  }
  .bootstrap-iso .order-lg-4 {
    order: 4;
  }
  .bootstrap-iso .order-lg-5 {
    order: 5;
  }
  .bootstrap-iso .order-lg-6 {
    order: 6;
  }
  .bootstrap-iso .order-lg-7 {
    order: 7;
  }
  .bootstrap-iso .order-lg-8 {
    order: 8;
  }
  .bootstrap-iso .order-lg-9 {
    order: 9;
  }
  .bootstrap-iso .order-lg-10 {
    order: 10;
  }
  .bootstrap-iso .order-lg-11 {
    order: 11;
  }
  .bootstrap-iso .order-lg-12 {
    order: 12;
  }
  .bootstrap-iso .offset-lg-0 {
    margin-left: 0;
  }
  .bootstrap-iso .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .bootstrap-iso .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .bootstrap-iso .offset-lg-3 {
    margin-left: 25%;
  }
  .bootstrap-iso .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .bootstrap-iso .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .bootstrap-iso .offset-lg-6 {
    margin-left: 50%;
  }
  .bootstrap-iso .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .bootstrap-iso .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .bootstrap-iso .offset-lg-9 {
    margin-left: 75%;
  }
  .bootstrap-iso .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .bootstrap-iso .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .bootstrap-iso .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .bootstrap-iso .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-iso .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-iso .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .bootstrap-iso .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-iso .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bootstrap-iso .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .bootstrap-iso .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .bootstrap-iso .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .bootstrap-iso .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .bootstrap-iso .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .bootstrap-iso .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .bootstrap-iso .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .bootstrap-iso .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .bootstrap-iso .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .bootstrap-iso .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .bootstrap-iso .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .bootstrap-iso .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .bootstrap-iso .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .bootstrap-iso .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bootstrap-iso .order-xl-first {
    order: -1;
  }
  .bootstrap-iso .order-xl-last {
    order: 13;
  }
  .bootstrap-iso .order-xl-0 {
    order: 0;
  }
  .bootstrap-iso .order-xl-1 {
    order: 1;
  }
  .bootstrap-iso .order-xl-2 {
    order: 2;
  }
  .bootstrap-iso .order-xl-3 {
    order: 3;
  }
  .bootstrap-iso .order-xl-4 {
    order: 4;
  }
  .bootstrap-iso .order-xl-5 {
    order: 5;
  }
  .bootstrap-iso .order-xl-6 {
    order: 6;
  }
  .bootstrap-iso .order-xl-7 {
    order: 7;
  }
  .bootstrap-iso .order-xl-8 {
    order: 8;
  }
  .bootstrap-iso .order-xl-9 {
    order: 9;
  }
  .bootstrap-iso .order-xl-10 {
    order: 10;
  }
  .bootstrap-iso .order-xl-11 {
    order: 11;
  }
  .bootstrap-iso .order-xl-12 {
    order: 12;
  }
  .bootstrap-iso .offset-xl-0 {
    margin-left: 0;
  }
  .bootstrap-iso .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .bootstrap-iso .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .bootstrap-iso .offset-xl-3 {
    margin-left: 25%;
  }
  .bootstrap-iso .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .bootstrap-iso .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .bootstrap-iso .offset-xl-6 {
    margin-left: 50%;
  }
  .bootstrap-iso .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .bootstrap-iso .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .bootstrap-iso .offset-xl-9 {
    margin-left: 75%;
  }
  .bootstrap-iso .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .bootstrap-iso .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.bootstrap-iso .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.bootstrap-iso .table th,
.bootstrap-iso .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.bootstrap-iso .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.bootstrap-iso .table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.bootstrap-iso .table-sm th,
.bootstrap-iso .table-sm td {
  padding: 0.3rem;
}

.bootstrap-iso .table-bordered {
  border: 1px solid #dee2e6;
}

.bootstrap-iso .table-bordered th,
.bootstrap-iso .table-bordered td {
  border: 1px solid #dee2e6;
}

.bootstrap-iso .table-bordered thead th,
.bootstrap-iso .table-bordered thead td {
  border-bottom-width: 2px;
}

.bootstrap-iso .table-borderless th,
.bootstrap-iso .table-borderless td,
.bootstrap-iso .table-borderless thead th,
.bootstrap-iso .table-borderless tbody + tbody {
  border: 0;
}

.bootstrap-iso .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.bootstrap-iso .table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.bootstrap-iso .table-primary,
.bootstrap-iso .table-primary > th,
.bootstrap-iso .table-primary > td {
  background-color: #b8daff;
}

.bootstrap-iso .table-primary th,
.bootstrap-iso .table-primary td,
.bootstrap-iso .table-primary thead th,
.bootstrap-iso .table-primary tbody + tbody {
  border-color: #7abaff;
}

.bootstrap-iso .table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.bootstrap-iso .table-hover .table-primary:hover > td,
.bootstrap-iso .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.bootstrap-iso .table-secondary,
.bootstrap-iso .table-secondary > th,
.bootstrap-iso .table-secondary > td {
  background-color: #d6d8db;
}

.bootstrap-iso .table-secondary th,
.bootstrap-iso .table-secondary td,
.bootstrap-iso .table-secondary thead th,
.bootstrap-iso .table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.bootstrap-iso .table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.bootstrap-iso .table-hover .table-secondary:hover > td,
.bootstrap-iso .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.bootstrap-iso .table-success,
.bootstrap-iso .table-success > th,
.bootstrap-iso .table-success > td {
  background-color: #c3e6cb;
}

.bootstrap-iso .table-success th,
.bootstrap-iso .table-success td,
.bootstrap-iso .table-success thead th,
.bootstrap-iso .table-success tbody + tbody {
  border-color: #8fd19e;
}

.bootstrap-iso .table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.bootstrap-iso .table-hover .table-success:hover > td,
.bootstrap-iso .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.bootstrap-iso .table-info,
.bootstrap-iso .table-info > th,
.bootstrap-iso .table-info > td {
  background-color: #bee5eb;
}

.bootstrap-iso .table-info th,
.bootstrap-iso .table-info td,
.bootstrap-iso .table-info thead th,
.bootstrap-iso .table-info tbody + tbody {
  border-color: #86cfda;
}

.bootstrap-iso .table-hover .table-info:hover {
  background-color: #abdde5;
}

.bootstrap-iso .table-hover .table-info:hover > td,
.bootstrap-iso .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.bootstrap-iso .table-warning,
.bootstrap-iso .table-warning > th,
.bootstrap-iso .table-warning > td {
  background-color: #ffeeba;
}

.bootstrap-iso .table-warning th,
.bootstrap-iso .table-warning td,
.bootstrap-iso .table-warning thead th,
.bootstrap-iso .table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.bootstrap-iso .table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.bootstrap-iso .table-hover .table-warning:hover > td,
.bootstrap-iso .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.bootstrap-iso .table-danger,
.bootstrap-iso .table-danger > th,
.bootstrap-iso .table-danger > td {
  background-color: #f5c6cb;
}

.bootstrap-iso .table-danger th,
.bootstrap-iso .table-danger td,
.bootstrap-iso .table-danger thead th,
.bootstrap-iso .table-danger tbody + tbody {
  border-color: #ed969e;
}

.bootstrap-iso .table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.bootstrap-iso .table-hover .table-danger:hover > td,
.bootstrap-iso .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.bootstrap-iso .table-light,
.bootstrap-iso .table-light > th,
.bootstrap-iso .table-light > td {
  background-color: #fdfdfe;
}

.bootstrap-iso .table-light th,
.bootstrap-iso .table-light td,
.bootstrap-iso .table-light thead th,
.bootstrap-iso .table-light tbody + tbody {
  border-color: #fbfcfc;
}

.bootstrap-iso .table-hover .table-light:hover {
  background-color: #ececf6;
}

.bootstrap-iso .table-hover .table-light:hover > td,
.bootstrap-iso .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.bootstrap-iso .table-dark,
.bootstrap-iso .table-dark > th,
.bootstrap-iso .table-dark > td {
  background-color: #c6c8ca;
}

.bootstrap-iso .table-dark th,
.bootstrap-iso .table-dark td,
.bootstrap-iso .table-dark thead th,
.bootstrap-iso .table-dark tbody + tbody {
  border-color: #95999c;
}

.bootstrap-iso .table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.bootstrap-iso .table-hover .table-dark:hover > td,
.bootstrap-iso .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.bootstrap-iso .table-active,
.bootstrap-iso .table-active > th,
.bootstrap-iso .table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.bootstrap-iso .table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.bootstrap-iso .table-hover .table-active:hover > td,
.bootstrap-iso .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.bootstrap-iso .table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.bootstrap-iso .table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.bootstrap-iso .table-dark {
  color: #fff;
  background-color: #343a40;
}

.bootstrap-iso .table-dark th,
.bootstrap-iso .table-dark td,
.bootstrap-iso .table-dark thead th {
  border-color: #454d55;
}

.bootstrap-iso .table-dark.table-bordered {
  border: 0;
}

.bootstrap-iso .table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.bootstrap-iso .table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .bootstrap-iso .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bootstrap-iso .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .bootstrap-iso .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bootstrap-iso .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .bootstrap-iso .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bootstrap-iso .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .bootstrap-iso .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bootstrap-iso .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.bootstrap-iso .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.bootstrap-iso .table-responsive > .table-bordered {
  border: 0;
}

.bootstrap-iso .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .form-control {
    transition: none;
  }
}

.bootstrap-iso .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.bootstrap-iso .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.bootstrap-iso .form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.bootstrap-iso .form-control:disabled, .bootstrap-iso .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.bootstrap-iso input[type="date"].form-control,
.bootstrap-iso input[type="time"].form-control,
.bootstrap-iso input[type="datetime-local"].form-control,
.bootstrap-iso input[type="month"].form-control {
  appearance: none;
}

.bootstrap-iso select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.bootstrap-iso select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.bootstrap-iso .form-control-file,
.bootstrap-iso .form-control-range {
  display: block;
  width: 100%;
}

.bootstrap-iso .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.bootstrap-iso .col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.bootstrap-iso .col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.bootstrap-iso .form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.bootstrap-iso .form-control-plaintext.form-control-sm, .bootstrap-iso .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.bootstrap-iso .form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.bootstrap-iso .form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.bootstrap-iso select.form-control[size], .bootstrap-iso select.form-control[multiple] {
  height: auto;
}

.bootstrap-iso textarea.form-control {
  height: auto;
}

.bootstrap-iso .form-group {
  margin-bottom: 1rem;
}

.bootstrap-iso .form-text {
  display: block;
  margin-top: 0.25rem;
}

.bootstrap-iso .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.bootstrap-iso .form-row > .col,
.bootstrap-iso .form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.bootstrap-iso .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.bootstrap-iso .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.bootstrap-iso .form-check-input[disabled] ~ .form-check-label,
.bootstrap-iso .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.bootstrap-iso .form-check-label {
  margin-bottom: 0;
}

.bootstrap-iso .form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.bootstrap-iso .form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.bootstrap-iso .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.bootstrap-iso .valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .bootstrap-iso .valid-tooltip,
.form-row > [class*="col-"] > .bootstrap-iso .valid-tooltip {
  left: 5px;
}

.was-validated .bootstrap-iso:valid ~ .valid-feedback,
.was-validated .bootstrap-iso:valid ~ .valid-tooltip, .bootstrap-iso.is-valid ~ .valid-feedback,
.bootstrap-iso.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .bootstrap-iso .form-control:valid, .bootstrap-iso .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .bootstrap-iso .form-control:valid:focus, .bootstrap-iso .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .bootstrap-iso select.form-control:valid, .bootstrap-iso select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated .bootstrap-iso textarea.form-control:valid, .bootstrap-iso textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .bootstrap-iso .custom-select:valid, .bootstrap-iso .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .bootstrap-iso .custom-select:valid:focus, .bootstrap-iso .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .bootstrap-iso .form-check-input:valid ~ .form-check-label, .bootstrap-iso .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .bootstrap-iso .form-check-input:valid ~ .valid-feedback,
.was-validated .bootstrap-iso .form-check-input:valid ~ .valid-tooltip, .bootstrap-iso .form-check-input.is-valid ~ .valid-feedback,
.bootstrap-iso .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .bootstrap-iso .custom-control-input:valid ~ .custom-control-label, .bootstrap-iso .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .bootstrap-iso .custom-control-input:valid ~ .custom-control-label::before, .bootstrap-iso .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .bootstrap-iso .custom-control-input:valid:checked ~ .custom-control-label::before, .bootstrap-iso .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .bootstrap-iso .custom-control-input:valid:focus ~ .custom-control-label::before, .bootstrap-iso .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .bootstrap-iso .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .bootstrap-iso .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .bootstrap-iso .custom-file-input:valid ~ .custom-file-label, .bootstrap-iso .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .bootstrap-iso .custom-file-input:valid:focus ~ .custom-file-label, .bootstrap-iso .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.bootstrap-iso .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.bootstrap-iso .invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .bootstrap-iso .invalid-tooltip,
.form-row > [class*="col-"] > .bootstrap-iso .invalid-tooltip {
  left: 5px;
}

.was-validated .bootstrap-iso:invalid ~ .invalid-feedback,
.was-validated .bootstrap-iso:invalid ~ .invalid-tooltip, .bootstrap-iso.is-invalid ~ .invalid-feedback,
.bootstrap-iso.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .bootstrap-iso .form-control:invalid, .bootstrap-iso .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .bootstrap-iso .form-control:invalid:focus, .bootstrap-iso .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .bootstrap-iso select.form-control:invalid, .bootstrap-iso select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated .bootstrap-iso textarea.form-control:invalid, .bootstrap-iso textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .bootstrap-iso .custom-select:invalid, .bootstrap-iso .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .bootstrap-iso .custom-select:invalid:focus, .bootstrap-iso .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .bootstrap-iso .form-check-input:invalid ~ .form-check-label, .bootstrap-iso .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .bootstrap-iso .form-check-input:invalid ~ .invalid-feedback,
.was-validated .bootstrap-iso .form-check-input:invalid ~ .invalid-tooltip, .bootstrap-iso .form-check-input.is-invalid ~ .invalid-feedback,
.bootstrap-iso .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .bootstrap-iso .custom-control-input:invalid ~ .custom-control-label, .bootstrap-iso .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .bootstrap-iso .custom-control-input:invalid ~ .custom-control-label::before, .bootstrap-iso .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .bootstrap-iso .custom-control-input:invalid:checked ~ .custom-control-label::before, .bootstrap-iso .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .bootstrap-iso .custom-control-input:invalid:focus ~ .custom-control-label::before, .bootstrap-iso .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .bootstrap-iso .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .bootstrap-iso .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .bootstrap-iso .custom-file-input:invalid ~ .custom-file-label, .bootstrap-iso .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .bootstrap-iso .custom-file-input:invalid:focus ~ .custom-file-label, .bootstrap-iso .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.bootstrap-iso .form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.bootstrap-iso .form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .bootstrap-iso .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .bootstrap-iso .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .bootstrap-iso .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .bootstrap-iso .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .bootstrap-iso .form-inline .input-group,
  .bootstrap-iso .form-inline .custom-select {
    width: auto;
  }
  .bootstrap-iso .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .bootstrap-iso .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .bootstrap-iso .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .bootstrap-iso .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.bootstrap-iso .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .btn {
    transition: none;
  }
}

.bootstrap-iso .btn:hover {
  color: #212529;
  text-decoration: none;
}

.bootstrap-iso .btn:focus, .bootstrap-iso .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.bootstrap-iso .btn.disabled, .bootstrap-iso .btn:disabled {
  opacity: 0.65;
}

.bootstrap-iso .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.bootstrap-iso a.btn.disabled,
.bootstrap-iso fieldset:disabled a.btn {
  pointer-events: none;
}

.bootstrap-iso .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.bootstrap-iso .btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.bootstrap-iso .btn-primary:focus, .bootstrap-iso .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.bootstrap-iso .btn-primary.disabled, .bootstrap-iso .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.bootstrap-iso .btn-primary:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-primary:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.bootstrap-iso .btn-primary:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.bootstrap-iso .btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.bootstrap-iso .btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.bootstrap-iso .btn-secondary:focus, .bootstrap-iso .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.bootstrap-iso .btn-secondary.disabled, .bootstrap-iso .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.bootstrap-iso .btn-secondary:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-secondary:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.bootstrap-iso .btn-secondary:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.bootstrap-iso .btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.bootstrap-iso .btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.bootstrap-iso .btn-success:focus, .bootstrap-iso .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.bootstrap-iso .btn-success.disabled, .bootstrap-iso .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.bootstrap-iso .btn-success:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-success:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.bootstrap-iso .btn-success:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.bootstrap-iso .btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.bootstrap-iso .btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.bootstrap-iso .btn-info:focus, .bootstrap-iso .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.bootstrap-iso .btn-info.disabled, .bootstrap-iso .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.bootstrap-iso .btn-info:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-info:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.bootstrap-iso .btn-info:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.bootstrap-iso .btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.bootstrap-iso .btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.bootstrap-iso .btn-warning:focus, .bootstrap-iso .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.bootstrap-iso .btn-warning.disabled, .bootstrap-iso .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.bootstrap-iso .btn-warning:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-warning:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.bootstrap-iso .btn-warning:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.bootstrap-iso .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.bootstrap-iso .btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.bootstrap-iso .btn-danger:focus, .bootstrap-iso .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.bootstrap-iso .btn-danger.disabled, .bootstrap-iso .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.bootstrap-iso .btn-danger:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-danger:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.bootstrap-iso .btn-danger:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.bootstrap-iso .btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.bootstrap-iso .btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.bootstrap-iso .btn-light:focus, .bootstrap-iso .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.bootstrap-iso .btn-light.disabled, .bootstrap-iso .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.bootstrap-iso .btn-light:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-light:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.bootstrap-iso .btn-light:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.bootstrap-iso .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.bootstrap-iso .btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.bootstrap-iso .btn-dark:focus, .bootstrap-iso .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.bootstrap-iso .btn-dark.disabled, .bootstrap-iso .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.bootstrap-iso .btn-dark:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-dark:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.bootstrap-iso .btn-dark:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.bootstrap-iso .btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.bootstrap-iso .btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.bootstrap-iso .btn-outline-primary:focus, .bootstrap-iso .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.bootstrap-iso .btn-outline-primary.disabled, .bootstrap-iso .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.bootstrap-iso .btn-outline-primary:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.bootstrap-iso .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.bootstrap-iso .btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.bootstrap-iso .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.bootstrap-iso .btn-outline-secondary:focus, .bootstrap-iso .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.bootstrap-iso .btn-outline-secondary.disabled, .bootstrap-iso .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.bootstrap-iso .btn-outline-secondary:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.bootstrap-iso .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.bootstrap-iso .btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.bootstrap-iso .btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.bootstrap-iso .btn-outline-success:focus, .bootstrap-iso .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.bootstrap-iso .btn-outline-success.disabled, .bootstrap-iso .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.bootstrap-iso .btn-outline-success:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.bootstrap-iso .btn-outline-success:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.bootstrap-iso .btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.bootstrap-iso .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.bootstrap-iso .btn-outline-info:focus, .bootstrap-iso .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.bootstrap-iso .btn-outline-info.disabled, .bootstrap-iso .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.bootstrap-iso .btn-outline-info:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.bootstrap-iso .btn-outline-info:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.bootstrap-iso .btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.bootstrap-iso .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.bootstrap-iso .btn-outline-warning:focus, .bootstrap-iso .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.bootstrap-iso .btn-outline-warning.disabled, .bootstrap-iso .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.bootstrap-iso .btn-outline-warning:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.bootstrap-iso .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.bootstrap-iso .btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.bootstrap-iso .btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.bootstrap-iso .btn-outline-danger:focus, .bootstrap-iso .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.bootstrap-iso .btn-outline-danger.disabled, .bootstrap-iso .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.bootstrap-iso .btn-outline-danger:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.bootstrap-iso .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.bootstrap-iso .btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.bootstrap-iso .btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.bootstrap-iso .btn-outline-light:focus, .bootstrap-iso .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.bootstrap-iso .btn-outline-light.disabled, .bootstrap-iso .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.bootstrap-iso .btn-outline-light:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.bootstrap-iso .btn-outline-light:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.bootstrap-iso .btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.bootstrap-iso .btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.bootstrap-iso .btn-outline-dark:focus, .bootstrap-iso .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.bootstrap-iso .btn-outline-dark.disabled, .bootstrap-iso .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.bootstrap-iso .btn-outline-dark:not(:disabled):not(.disabled):active, .bootstrap-iso .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .bootstrap-iso .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.bootstrap-iso .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .bootstrap-iso .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .bootstrap-iso .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.bootstrap-iso .btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.bootstrap-iso .btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.bootstrap-iso .btn-link:focus, .bootstrap-iso .btn-link.focus {
  text-decoration: underline;
}

.bootstrap-iso .btn-link:disabled, .bootstrap-iso .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.bootstrap-iso .btn-lg, .bootstrap-iso .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.bootstrap-iso .btn-sm, .bootstrap-iso .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.bootstrap-iso .btn-block {
  display: block;
  width: 100%;
}

.bootstrap-iso .btn-block + .btn-block {
  margin-top: 0.5rem;
}

.bootstrap-iso input[type="submit"].btn-block,
.bootstrap-iso input[type="reset"].btn-block,
.bootstrap-iso input[type="button"].btn-block {
  width: 100%;
}

.bootstrap-iso .fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .fade {
    transition: none;
  }
}

.bootstrap-iso .fade:not(.show) {
  opacity: 0;
}

.bootstrap-iso .collapse:not(.show) {
  display: none;
}

.bootstrap-iso .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .collapsing {
    transition: none;
  }
}

.bootstrap-iso .collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .collapsing.width {
    transition: none;
  }
}

.bootstrap-iso .dropup,
.bootstrap-iso .dropright,
.bootstrap-iso .dropdown,
.bootstrap-iso .dropleft {
  position: relative;
}

.bootstrap-iso .dropdown-toggle {
  white-space: nowrap;
}

.bootstrap-iso .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.bootstrap-iso .dropdown-toggle:empty::after {
  margin-left: 0;
}

.bootstrap-iso .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.bootstrap-iso .dropdown-menu-left {
  right: auto;
  left: 0;
}

.bootstrap-iso .dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .bootstrap-iso .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .bootstrap-iso .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .bootstrap-iso .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .bootstrap-iso .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .bootstrap-iso .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .bootstrap-iso .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .bootstrap-iso .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .bootstrap-iso .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.bootstrap-iso .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.bootstrap-iso .dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.bootstrap-iso .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.bootstrap-iso .dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.bootstrap-iso .dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.bootstrap-iso .dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.bootstrap-iso .dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.bootstrap-iso .dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.bootstrap-iso .dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.bootstrap-iso .dropleft .dropdown-toggle::after {
  display: none;
}

.bootstrap-iso .dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.bootstrap-iso .dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.bootstrap-iso .dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.bootstrap-iso .dropdown-menu[x-placement^="top"], .bootstrap-iso .dropdown-menu[x-placement^="right"], .bootstrap-iso .dropdown-menu[x-placement^="bottom"], .bootstrap-iso .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.bootstrap-iso .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.bootstrap-iso .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.bootstrap-iso .dropdown-item:hover, .bootstrap-iso .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.bootstrap-iso .dropdown-item.active, .bootstrap-iso .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.bootstrap-iso .dropdown-item.disabled, .bootstrap-iso .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.bootstrap-iso .dropdown-menu.show {
  display: block;
}

.bootstrap-iso .dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.bootstrap-iso .dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.bootstrap-iso .btn-group,
.bootstrap-iso .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.bootstrap-iso .btn-group > .btn,
.bootstrap-iso .btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.bootstrap-iso .btn-group > .btn:hover,
.bootstrap-iso .btn-group-vertical > .btn:hover {
  z-index: 1;
}

.bootstrap-iso .btn-group > .btn:focus, .bootstrap-iso .btn-group > .btn:active, .bootstrap-iso .btn-group > .btn.active,
.bootstrap-iso .btn-group-vertical > .btn:focus,
.bootstrap-iso .btn-group-vertical > .btn:active,
.bootstrap-iso .btn-group-vertical > .btn.active {
  z-index: 1;
}

.bootstrap-iso .btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.bootstrap-iso .btn-toolbar .input-group {
  width: auto;
}

.bootstrap-iso .btn-group > .btn:not(:first-child),
.bootstrap-iso .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.bootstrap-iso .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.bootstrap-iso .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bootstrap-iso .btn-group > .btn:not(:first-child),
.bootstrap-iso .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bootstrap-iso .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.bootstrap-iso .dropdown-toggle-split::after,
.dropup .bootstrap-iso .dropdown-toggle-split::after,
.dropright .bootstrap-iso .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .bootstrap-iso .dropdown-toggle-split::before {
  margin-right: 0;
}

.bootstrap-iso .btn-sm + .dropdown-toggle-split, .bootstrap-iso .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.bootstrap-iso .btn-lg + .dropdown-toggle-split, .bootstrap-iso .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.bootstrap-iso .btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.bootstrap-iso .btn-group-vertical > .btn,
.bootstrap-iso .btn-group-vertical > .btn-group {
  width: 100%;
}

.bootstrap-iso .btn-group-vertical > .btn:not(:first-child),
.bootstrap-iso .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.bootstrap-iso .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.bootstrap-iso .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.bootstrap-iso .btn-group-vertical > .btn:not(:first-child),
.bootstrap-iso .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bootstrap-iso .btn-group-toggle > .btn,
.bootstrap-iso .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.bootstrap-iso .btn-group-toggle > .btn input[type="radio"],
.bootstrap-iso .btn-group-toggle > .btn input[type="checkbox"],
.bootstrap-iso .btn-group-toggle > .btn-group > .btn input[type="radio"],
.bootstrap-iso .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.bootstrap-iso .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.bootstrap-iso .input-group > .form-control,
.bootstrap-iso .input-group > .form-control-plaintext,
.bootstrap-iso .input-group > .custom-select,
.bootstrap-iso .input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.bootstrap-iso .input-group > .form-control + .form-control,
.bootstrap-iso .input-group > .form-control + .custom-select,
.bootstrap-iso .input-group > .form-control + .custom-file,
.bootstrap-iso .input-group > .form-control-plaintext + .form-control,
.bootstrap-iso .input-group > .form-control-plaintext + .custom-select,
.bootstrap-iso .input-group > .form-control-plaintext + .custom-file,
.bootstrap-iso .input-group > .custom-select + .form-control,
.bootstrap-iso .input-group > .custom-select + .custom-select,
.bootstrap-iso .input-group > .custom-select + .custom-file,
.bootstrap-iso .input-group > .custom-file + .form-control,
.bootstrap-iso .input-group > .custom-file + .custom-select,
.bootstrap-iso .input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.bootstrap-iso .input-group > .form-control:focus,
.bootstrap-iso .input-group > .custom-select:focus,
.bootstrap-iso .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.bootstrap-iso .input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.bootstrap-iso .input-group > .form-control:not(:first-child),
.bootstrap-iso .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bootstrap-iso .input-group > .custom-file {
  display: flex;
  align-items: center;
}

.bootstrap-iso .input-group > .custom-file:not(:last-child) .custom-file-label,
.bootstrap-iso .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bootstrap-iso .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bootstrap-iso .input-group:not(.has-validation) > .form-control:not(:last-child),
.bootstrap-iso .input-group:not(.has-validation) > .custom-select:not(:last-child),
.bootstrap-iso .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.bootstrap-iso .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bootstrap-iso .input-group.has-validation > .form-control:nth-last-child(n + 3),
.bootstrap-iso .input-group.has-validation > .custom-select:nth-last-child(n + 3),
.bootstrap-iso .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
.bootstrap-iso .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bootstrap-iso .input-group-prepend,
.bootstrap-iso .input-group-append {
  display: flex;
}

.bootstrap-iso .input-group-prepend .btn,
.bootstrap-iso .input-group-append .btn {
  position: relative;
  z-index: 2;
}

.bootstrap-iso .input-group-prepend .btn:focus,
.bootstrap-iso .input-group-append .btn:focus {
  z-index: 3;
}

.bootstrap-iso .input-group-prepend .btn + .btn,
.bootstrap-iso .input-group-prepend .btn + .input-group-text,
.bootstrap-iso .input-group-prepend .input-group-text + .input-group-text,
.bootstrap-iso .input-group-prepend .input-group-text + .btn,
.bootstrap-iso .input-group-append .btn + .btn,
.bootstrap-iso .input-group-append .btn + .input-group-text,
.bootstrap-iso .input-group-append .input-group-text + .input-group-text,
.bootstrap-iso .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.bootstrap-iso .input-group-prepend {
  margin-right: -1px;
}

.bootstrap-iso .input-group-append {
  margin-left: -1px;
}

.bootstrap-iso .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.bootstrap-iso .input-group-text input[type="radio"],
.bootstrap-iso .input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.bootstrap-iso .input-group-lg > .form-control:not(textarea),
.bootstrap-iso .input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.bootstrap-iso .input-group-lg > .form-control,
.bootstrap-iso .input-group-lg > .custom-select,
.bootstrap-iso .input-group-lg > .input-group-prepend > .input-group-text,
.bootstrap-iso .input-group-lg > .input-group-append > .input-group-text,
.bootstrap-iso .input-group-lg > .input-group-prepend > .btn,
.bootstrap-iso .input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.bootstrap-iso .input-group-sm > .form-control:not(textarea),
.bootstrap-iso .input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.bootstrap-iso .input-group-sm > .form-control,
.bootstrap-iso .input-group-sm > .custom-select,
.bootstrap-iso .input-group-sm > .input-group-prepend > .input-group-text,
.bootstrap-iso .input-group-sm > .input-group-append > .input-group-text,
.bootstrap-iso .input-group-sm > .input-group-prepend > .btn,
.bootstrap-iso .input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.bootstrap-iso .input-group-lg > .custom-select,
.bootstrap-iso .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.bootstrap-iso .input-group > .input-group-prepend > .btn,
.bootstrap-iso .input-group > .input-group-prepend > .input-group-text,
.bootstrap-iso .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.bootstrap-iso .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.bootstrap-iso .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.bootstrap-iso .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.bootstrap-iso .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.bootstrap-iso .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bootstrap-iso .input-group > .input-group-append > .btn,
.bootstrap-iso .input-group > .input-group-append > .input-group-text,
.bootstrap-iso .input-group > .input-group-prepend:not(:first-child) > .btn,
.bootstrap-iso .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.bootstrap-iso .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.bootstrap-iso .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bootstrap-iso .custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.bootstrap-iso .custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.bootstrap-iso .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.bootstrap-iso .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.bootstrap-iso .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.bootstrap-iso .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.bootstrap-iso .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.bootstrap-iso .custom-control-input[disabled] ~ .custom-control-label, .bootstrap-iso .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.bootstrap-iso .custom-control-input[disabled] ~ .custom-control-label::before, .bootstrap-iso .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.bootstrap-iso .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.bootstrap-iso .custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.bootstrap-iso .custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.bootstrap-iso .custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.bootstrap-iso .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.bootstrap-iso .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.bootstrap-iso .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.bootstrap-iso .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.bootstrap-iso .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.bootstrap-iso .custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.bootstrap-iso .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.bootstrap-iso .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.bootstrap-iso .custom-switch {
  padding-left: 2.25rem;
}

.bootstrap-iso .custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.bootstrap-iso .custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.bootstrap-iso .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.bootstrap-iso .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.bootstrap-iso .custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.bootstrap-iso .custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.bootstrap-iso .custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.bootstrap-iso .custom-select[multiple], .bootstrap-iso .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.bootstrap-iso .custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.bootstrap-iso .custom-select::-ms-expand {
  display: none;
}

.bootstrap-iso .custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.bootstrap-iso .custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.bootstrap-iso .custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.bootstrap-iso .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.bootstrap-iso .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.bootstrap-iso .custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.bootstrap-iso .custom-file-input[disabled] ~ .custom-file-label,
.bootstrap-iso .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.bootstrap-iso .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.bootstrap-iso .custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.bootstrap-iso .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.bootstrap-iso .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.bootstrap-iso .custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.bootstrap-iso .custom-range:focus {
  outline: 0;
}

.bootstrap-iso .custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.bootstrap-iso .custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.bootstrap-iso .custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.bootstrap-iso .custom-range::-moz-focus-outer {
  border: 0;
}

.bootstrap-iso .custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.bootstrap-iso .custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.bootstrap-iso .custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.bootstrap-iso .custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.bootstrap-iso .custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.bootstrap-iso .custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.bootstrap-iso .custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .custom-range::-ms-thumb {
    transition: none;
  }
}

.bootstrap-iso .custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.bootstrap-iso .custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.bootstrap-iso .custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.bootstrap-iso .custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.bootstrap-iso .custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.bootstrap-iso .custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.bootstrap-iso .custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.bootstrap-iso .custom-range:disabled::-moz-range-track {
  cursor: default;
}

.bootstrap-iso .custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.bootstrap-iso .custom-control-label::before,
.bootstrap-iso .custom-file-label,
.bootstrap-iso .custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .custom-control-label::before,
  .bootstrap-iso .custom-file-label,
  .bootstrap-iso .custom-select {
    transition: none;
  }
}

.bootstrap-iso .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.bootstrap-iso .nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.bootstrap-iso .nav-link:hover, .bootstrap-iso .nav-link:focus {
  text-decoration: none;
}

.bootstrap-iso .nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.bootstrap-iso .nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.bootstrap-iso .nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.bootstrap-iso .nav-tabs .nav-link:hover, .bootstrap-iso .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}

.bootstrap-iso .nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.bootstrap-iso .nav-tabs .nav-link.active,
.bootstrap-iso .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.bootstrap-iso .nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bootstrap-iso .nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.bootstrap-iso .nav-pills .nav-link.active,
.bootstrap-iso .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.bootstrap-iso .nav-fill > .nav-link,
.bootstrap-iso .nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.bootstrap-iso .nav-justified > .nav-link,
.bootstrap-iso .nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.bootstrap-iso .tab-content > .tab-pane {
  display: none;
}

.bootstrap-iso .tab-content > .active {
  display: block;
}

.bootstrap-iso .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.bootstrap-iso .navbar .container,
.bootstrap-iso .navbar .container-fluid, .bootstrap-iso .navbar .container-sm, .bootstrap-iso .navbar .container-md, .bootstrap-iso .navbar .container-lg, .bootstrap-iso .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.bootstrap-iso .navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.bootstrap-iso .navbar-brand:hover, .bootstrap-iso .navbar-brand:focus {
  text-decoration: none;
}

.bootstrap-iso .navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.bootstrap-iso .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.bootstrap-iso .navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.bootstrap-iso .navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.bootstrap-iso .navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.bootstrap-iso .navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.bootstrap-iso .navbar-toggler:hover, .bootstrap-iso .navbar-toggler:focus {
  text-decoration: none;
}

.bootstrap-iso .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.bootstrap-iso .navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .bootstrap-iso .navbar-expand-sm > .container,
  .bootstrap-iso .navbar-expand-sm > .container-fluid, .bootstrap-iso .navbar-expand-sm > .container-sm, .bootstrap-iso .navbar-expand-sm > .container-md, .bootstrap-iso .navbar-expand-sm > .container-lg, .bootstrap-iso .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .bootstrap-iso .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .bootstrap-iso .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .bootstrap-iso .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-iso .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .bootstrap-iso .navbar-expand-sm > .container,
  .bootstrap-iso .navbar-expand-sm > .container-fluid, .bootstrap-iso .navbar-expand-sm > .container-sm, .bootstrap-iso .navbar-expand-sm > .container-md, .bootstrap-iso .navbar-expand-sm > .container-lg, .bootstrap-iso .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .bootstrap-iso .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .bootstrap-iso .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .bootstrap-iso .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .bootstrap-iso .navbar-expand-md > .container,
  .bootstrap-iso .navbar-expand-md > .container-fluid, .bootstrap-iso .navbar-expand-md > .container-sm, .bootstrap-iso .navbar-expand-md > .container-md, .bootstrap-iso .navbar-expand-md > .container-lg, .bootstrap-iso .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .bootstrap-iso .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .bootstrap-iso .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .bootstrap-iso .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-iso .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .bootstrap-iso .navbar-expand-md > .container,
  .bootstrap-iso .navbar-expand-md > .container-fluid, .bootstrap-iso .navbar-expand-md > .container-sm, .bootstrap-iso .navbar-expand-md > .container-md, .bootstrap-iso .navbar-expand-md > .container-lg, .bootstrap-iso .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .bootstrap-iso .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .bootstrap-iso .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .bootstrap-iso .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .bootstrap-iso .navbar-expand-lg > .container,
  .bootstrap-iso .navbar-expand-lg > .container-fluid, .bootstrap-iso .navbar-expand-lg > .container-sm, .bootstrap-iso .navbar-expand-lg > .container-md, .bootstrap-iso .navbar-expand-lg > .container-lg, .bootstrap-iso .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .bootstrap-iso .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .bootstrap-iso .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .bootstrap-iso .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-iso .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .bootstrap-iso .navbar-expand-lg > .container,
  .bootstrap-iso .navbar-expand-lg > .container-fluid, .bootstrap-iso .navbar-expand-lg > .container-sm, .bootstrap-iso .navbar-expand-lg > .container-md, .bootstrap-iso .navbar-expand-lg > .container-lg, .bootstrap-iso .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .bootstrap-iso .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .bootstrap-iso .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .bootstrap-iso .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .bootstrap-iso .navbar-expand-xl > .container,
  .bootstrap-iso .navbar-expand-xl > .container-fluid, .bootstrap-iso .navbar-expand-xl > .container-sm, .bootstrap-iso .navbar-expand-xl > .container-md, .bootstrap-iso .navbar-expand-xl > .container-lg, .bootstrap-iso .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .bootstrap-iso .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .bootstrap-iso .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .bootstrap-iso .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .bootstrap-iso .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .bootstrap-iso .navbar-expand-xl > .container,
  .bootstrap-iso .navbar-expand-xl > .container-fluid, .bootstrap-iso .navbar-expand-xl > .container-sm, .bootstrap-iso .navbar-expand-xl > .container-md, .bootstrap-iso .navbar-expand-xl > .container-lg, .bootstrap-iso .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .bootstrap-iso .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .bootstrap-iso .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .bootstrap-iso .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.bootstrap-iso .navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.bootstrap-iso .navbar-expand > .container,
.bootstrap-iso .navbar-expand > .container-fluid, .bootstrap-iso .navbar-expand > .container-sm, .bootstrap-iso .navbar-expand > .container-md, .bootstrap-iso .navbar-expand > .container-lg, .bootstrap-iso .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.bootstrap-iso .navbar-expand .navbar-nav {
  flex-direction: row;
}

.bootstrap-iso .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.bootstrap-iso .navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.bootstrap-iso .navbar-expand > .container,
.bootstrap-iso .navbar-expand > .container-fluid, .bootstrap-iso .navbar-expand > .container-sm, .bootstrap-iso .navbar-expand > .container-md, .bootstrap-iso .navbar-expand > .container-lg, .bootstrap-iso .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.bootstrap-iso .navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.bootstrap-iso .navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.bootstrap-iso .navbar-expand .navbar-toggler {
  display: none;
}

.bootstrap-iso .navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.bootstrap-iso .navbar-light .navbar-brand:hover, .bootstrap-iso .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.bootstrap-iso .navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.bootstrap-iso .navbar-light .navbar-nav .nav-link:hover, .bootstrap-iso .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.bootstrap-iso .navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.bootstrap-iso .navbar-light .navbar-nav .show > .nav-link,
.bootstrap-iso .navbar-light .navbar-nav .active > .nav-link,
.bootstrap-iso .navbar-light .navbar-nav .nav-link.show,
.bootstrap-iso .navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.bootstrap-iso .navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.bootstrap-iso .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.bootstrap-iso .navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.bootstrap-iso .navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.bootstrap-iso .navbar-light .navbar-text a:hover, .bootstrap-iso .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.bootstrap-iso .navbar-dark .navbar-brand {
  color: #fff;
}

.bootstrap-iso .navbar-dark .navbar-brand:hover, .bootstrap-iso .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.bootstrap-iso .navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.bootstrap-iso .navbar-dark .navbar-nav .nav-link:hover, .bootstrap-iso .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.bootstrap-iso .navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.bootstrap-iso .navbar-dark .navbar-nav .show > .nav-link,
.bootstrap-iso .navbar-dark .navbar-nav .active > .nav-link,
.bootstrap-iso .navbar-dark .navbar-nav .nav-link.show,
.bootstrap-iso .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.bootstrap-iso .navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.bootstrap-iso .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.bootstrap-iso .navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.bootstrap-iso .navbar-dark .navbar-text a {
  color: #fff;
}

.bootstrap-iso .navbar-dark .navbar-text a:hover, .bootstrap-iso .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.bootstrap-iso .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.bootstrap-iso .card > hr {
  margin-right: 0;
  margin-left: 0;
}

.bootstrap-iso .card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.bootstrap-iso .card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.bootstrap-iso .card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.bootstrap-iso .card > .card-header + .list-group,
.bootstrap-iso .card > .list-group + .card-footer {
  border-top: 0;
}

.bootstrap-iso .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.bootstrap-iso .card-title {
  margin-bottom: 0.75rem;
}

.bootstrap-iso .card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.bootstrap-iso .card-text:last-child {
  margin-bottom: 0;
}

.bootstrap-iso .card-link:hover {
  text-decoration: none;
}

.bootstrap-iso .card-link + .card-link {
  margin-left: 1.25rem;
}

.bootstrap-iso .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.bootstrap-iso .card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.bootstrap-iso .card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.bootstrap-iso .card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.bootstrap-iso .card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.bootstrap-iso .card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.bootstrap-iso .card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.bootstrap-iso .card-img,
.bootstrap-iso .card-img-top,
.bootstrap-iso .card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.bootstrap-iso .card-img,
.bootstrap-iso .card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.bootstrap-iso .card-img,
.bootstrap-iso .card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.bootstrap-iso .card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .bootstrap-iso .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .bootstrap-iso .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.bootstrap-iso .card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .bootstrap-iso .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .bootstrap-iso .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .bootstrap-iso .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .bootstrap-iso .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .bootstrap-iso .card-group > .card:not(:last-child) .card-img-top,
  .bootstrap-iso .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .bootstrap-iso .card-group > .card:not(:last-child) .card-img-bottom,
  .bootstrap-iso .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .bootstrap-iso .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .bootstrap-iso .card-group > .card:not(:first-child) .card-img-top,
  .bootstrap-iso .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .bootstrap-iso .card-group > .card:not(:first-child) .card-img-bottom,
  .bootstrap-iso .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.bootstrap-iso .card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .bootstrap-iso .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .bootstrap-iso .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.bootstrap-iso .accordion {
  overflow-anchor: none;
}

.bootstrap-iso .accordion > .card {
  overflow: hidden;
}

.bootstrap-iso .accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.bootstrap-iso .accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bootstrap-iso .accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.bootstrap-iso .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.bootstrap-iso .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.bootstrap-iso .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.bootstrap-iso .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.bootstrap-iso .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.bootstrap-iso .breadcrumb-item.active {
  color: #6c757d;
}

.bootstrap-iso .pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.bootstrap-iso .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.bootstrap-iso .page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.bootstrap-iso .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.bootstrap-iso .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.bootstrap-iso .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.bootstrap-iso .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.bootstrap-iso .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.bootstrap-iso .pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.bootstrap-iso .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.bootstrap-iso .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.bootstrap-iso .pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.bootstrap-iso .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.bootstrap-iso .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.bootstrap-iso .badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .badge {
    transition: none;
  }
}

a.bootstrap-iso .badge:hover, a.bootstrap-iso .badge:focus {
  text-decoration: none;
}

.bootstrap-iso .badge:empty {
  display: none;
}

.bootstrap-iso .btn .badge {
  position: relative;
  top: -1px;
}

.bootstrap-iso .badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.bootstrap-iso .badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.bootstrap-iso .badge-primary:hover, a.bootstrap-iso .badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

a.bootstrap-iso .badge-primary:focus, a.bootstrap-iso .badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.bootstrap-iso .badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.bootstrap-iso .badge-secondary:hover, a.bootstrap-iso .badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.bootstrap-iso .badge-secondary:focus, a.bootstrap-iso .badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.bootstrap-iso .badge-success {
  color: #fff;
  background-color: #28a745;
}

a.bootstrap-iso .badge-success:hover, a.bootstrap-iso .badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.bootstrap-iso .badge-success:focus, a.bootstrap-iso .badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.bootstrap-iso .badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.bootstrap-iso .badge-info:hover, a.bootstrap-iso .badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.bootstrap-iso .badge-info:focus, a.bootstrap-iso .badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.bootstrap-iso .badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.bootstrap-iso .badge-warning:hover, a.bootstrap-iso .badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.bootstrap-iso .badge-warning:focus, a.bootstrap-iso .badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.bootstrap-iso .badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.bootstrap-iso .badge-danger:hover, a.bootstrap-iso .badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.bootstrap-iso .badge-danger:focus, a.bootstrap-iso .badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.bootstrap-iso .badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.bootstrap-iso .badge-light:hover, a.bootstrap-iso .badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.bootstrap-iso .badge-light:focus, a.bootstrap-iso .badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.bootstrap-iso .badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.bootstrap-iso .badge-dark:hover, a.bootstrap-iso .badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.bootstrap-iso .badge-dark:focus, a.bootstrap-iso .badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.bootstrap-iso .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .bootstrap-iso .jumbotron {
    padding: 4rem 2rem;
  }
}

.bootstrap-iso .jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.bootstrap-iso .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.bootstrap-iso .alert-heading {
  color: inherit;
}

.bootstrap-iso .alert-link {
  font-weight: 700;
}

.bootstrap-iso .alert-dismissible {
  padding-right: 4rem;
}

.bootstrap-iso .alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.bootstrap-iso .alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.bootstrap-iso .alert-primary hr {
  border-top-color: #9fcdff;
}

.bootstrap-iso .alert-primary .alert-link {
  color: #002752;
}

.bootstrap-iso .alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.bootstrap-iso .alert-secondary hr {
  border-top-color: #c8cbcf;
}

.bootstrap-iso .alert-secondary .alert-link {
  color: #202326;
}

.bootstrap-iso .alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.bootstrap-iso .alert-success hr {
  border-top-color: #b1dfbb;
}

.bootstrap-iso .alert-success .alert-link {
  color: #0b2e13;
}

.bootstrap-iso .alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.bootstrap-iso .alert-info hr {
  border-top-color: #abdde5;
}

.bootstrap-iso .alert-info .alert-link {
  color: #062c33;
}

.bootstrap-iso .alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.bootstrap-iso .alert-warning hr {
  border-top-color: #ffe8a1;
}

.bootstrap-iso .alert-warning .alert-link {
  color: #533f03;
}

.bootstrap-iso .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.bootstrap-iso .alert-danger hr {
  border-top-color: #f1b0b7;
}

.bootstrap-iso .alert-danger .alert-link {
  color: #491217;
}

.bootstrap-iso .alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.bootstrap-iso .alert-light hr {
  border-top-color: #ececf6;
}

.bootstrap-iso .alert-light .alert-link {
  color: #686868;
}

.bootstrap-iso .alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.bootstrap-iso .alert-dark hr {
  border-top-color: #b9bbbe;
}

.bootstrap-iso .alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.bootstrap-iso .progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.bootstrap-iso .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .progress-bar {
    transition: none;
  }
}

.bootstrap-iso .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.bootstrap-iso .progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .progress-bar-animated {
    animation: none;
  }
}

.bootstrap-iso .media {
  display: flex;
  align-items: flex-start;
}

.bootstrap-iso .media-body {
  flex: 1;
}

.bootstrap-iso .list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.bootstrap-iso .list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.bootstrap-iso .list-group-item-action:hover, .bootstrap-iso .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.bootstrap-iso .list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.bootstrap-iso .list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.bootstrap-iso .list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.bootstrap-iso .list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.bootstrap-iso .list-group-item.disabled, .bootstrap-iso .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.bootstrap-iso .list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.bootstrap-iso .list-group-item + .bootstrap-iso .list-group-item {
  border-top-width: 0;
}

.bootstrap-iso .list-group-item + .bootstrap-iso .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.bootstrap-iso .list-group-horizontal {
  flex-direction: row;
}

.bootstrap-iso .list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.bootstrap-iso .list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.bootstrap-iso .list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.bootstrap-iso .list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.bootstrap-iso .list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .bootstrap-iso .list-group-horizontal-sm {
    flex-direction: row;
  }
  .bootstrap-iso .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .bootstrap-iso .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .bootstrap-iso .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .bootstrap-iso .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .bootstrap-iso .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .bootstrap-iso .list-group-horizontal-md {
    flex-direction: row;
  }
  .bootstrap-iso .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .bootstrap-iso .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .bootstrap-iso .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .bootstrap-iso .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .bootstrap-iso .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .bootstrap-iso .list-group-horizontal-lg {
    flex-direction: row;
  }
  .bootstrap-iso .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .bootstrap-iso .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .bootstrap-iso .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .bootstrap-iso .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .bootstrap-iso .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .bootstrap-iso .list-group-horizontal-xl {
    flex-direction: row;
  }
  .bootstrap-iso .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .bootstrap-iso .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .bootstrap-iso .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .bootstrap-iso .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .bootstrap-iso .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.bootstrap-iso .list-group-flush {
  border-radius: 0;
}

.bootstrap-iso .list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.bootstrap-iso .list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.bootstrap-iso .list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.bootstrap-iso .list-group-item-primary.list-group-item-action:hover, .bootstrap-iso .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.bootstrap-iso .list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.bootstrap-iso .list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.bootstrap-iso .list-group-item-secondary.list-group-item-action:hover, .bootstrap-iso .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.bootstrap-iso .list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.bootstrap-iso .list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.bootstrap-iso .list-group-item-success.list-group-item-action:hover, .bootstrap-iso .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.bootstrap-iso .list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.bootstrap-iso .list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.bootstrap-iso .list-group-item-info.list-group-item-action:hover, .bootstrap-iso .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.bootstrap-iso .list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.bootstrap-iso .list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.bootstrap-iso .list-group-item-warning.list-group-item-action:hover, .bootstrap-iso .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.bootstrap-iso .list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.bootstrap-iso .list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.bootstrap-iso .list-group-item-danger.list-group-item-action:hover, .bootstrap-iso .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.bootstrap-iso .list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.bootstrap-iso .list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.bootstrap-iso .list-group-item-light.list-group-item-action:hover, .bootstrap-iso .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.bootstrap-iso .list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.bootstrap-iso .list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.bootstrap-iso .list-group-item-dark.list-group-item-action:hover, .bootstrap-iso .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.bootstrap-iso .list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.bootstrap-iso .close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.bootstrap-iso .close:hover {
  color: #000;
  text-decoration: none;
}

.bootstrap-iso .close:not(:disabled):not(.disabled):hover, .bootstrap-iso .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

.bootstrap-iso button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.bootstrap-iso a.close.disabled {
  pointer-events: none;
}

.bootstrap-iso .toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.bootstrap-iso .toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.bootstrap-iso .toast.showing {
  opacity: 1;
}

.bootstrap-iso .toast.show {
  display: block;
  opacity: 1;
}

.bootstrap-iso .toast.hide {
  display: none;
}

.bootstrap-iso .toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.bootstrap-iso .toast-body {
  padding: 0.75rem;
}

.bootstrap-iso .modal-open {
  overflow: hidden;
}

.bootstrap-iso .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.bootstrap-iso .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.bootstrap-iso .modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .bootstrap-iso .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .bootstrap-iso .modal-dialog {
    transition: none;
  }
}

.modal.show .bootstrap-iso .modal-dialog {
  transform: none;
}

.modal.modal-static .bootstrap-iso .modal-dialog {
  transform: scale(1.02);
}

.bootstrap-iso .modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.bootstrap-iso .modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.bootstrap-iso .modal-dialog-scrollable .modal-header,
.bootstrap-iso .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.bootstrap-iso .modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.bootstrap-iso .modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.bootstrap-iso .modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.bootstrap-iso .modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.bootstrap-iso .modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.bootstrap-iso .modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.bootstrap-iso .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.bootstrap-iso .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.bootstrap-iso .modal-backdrop.fade {
  opacity: 0;
}

.bootstrap-iso .modal-backdrop.show {
  opacity: 0.5;
}

.bootstrap-iso .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.bootstrap-iso .modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.bootstrap-iso .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.bootstrap-iso .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.bootstrap-iso .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.bootstrap-iso .modal-footer > * {
  margin: 0.25rem;
}

.bootstrap-iso .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .bootstrap-iso .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .bootstrap-iso .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .bootstrap-iso .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .bootstrap-iso .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .bootstrap-iso .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .bootstrap-iso .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .bootstrap-iso .modal-lg,
  .bootstrap-iso .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .bootstrap-iso .modal-xl {
    max-width: 1140px;
  }
}

.bootstrap-iso .tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.bootstrap-iso .tooltip.show {
  opacity: 0.9;
}

.bootstrap-iso .tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.bootstrap-iso .tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bootstrap-iso .bs-tooltip-top, .bootstrap-iso .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bootstrap-iso .bs-tooltip-top .arrow, .bootstrap-iso .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bootstrap-iso .bs-tooltip-top .arrow::before, .bootstrap-iso .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bootstrap-iso .bs-tooltip-right, .bootstrap-iso .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bootstrap-iso .bs-tooltip-right .arrow, .bootstrap-iso .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bootstrap-iso .bs-tooltip-right .arrow::before, .bootstrap-iso .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bootstrap-iso .bs-tooltip-bottom, .bootstrap-iso .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bootstrap-iso .bs-tooltip-bottom .arrow, .bootstrap-iso .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bootstrap-iso .bs-tooltip-bottom .arrow::before, .bootstrap-iso .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bootstrap-iso .bs-tooltip-left, .bootstrap-iso .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bootstrap-iso .bs-tooltip-left .arrow, .bootstrap-iso .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bootstrap-iso .bs-tooltip-left .arrow::before, .bootstrap-iso .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.bootstrap-iso .tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.bootstrap-iso .popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.bootstrap-iso .popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.bootstrap-iso .popover .arrow::before, .bootstrap-iso .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bootstrap-iso .bs-popover-top, .bootstrap-iso .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bootstrap-iso .bs-popover-top > .arrow, .bootstrap-iso .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bootstrap-iso .bs-popover-top > .arrow::before, .bootstrap-iso .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bootstrap-iso .bs-popover-top > .arrow::after, .bootstrap-iso .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bootstrap-iso .bs-popover-right, .bootstrap-iso .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bootstrap-iso .bs-popover-right > .arrow, .bootstrap-iso .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bootstrap-iso .bs-popover-right > .arrow::before, .bootstrap-iso .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bootstrap-iso .bs-popover-right > .arrow::after, .bootstrap-iso .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bootstrap-iso .bs-popover-bottom, .bootstrap-iso .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bootstrap-iso .bs-popover-bottom > .arrow, .bootstrap-iso .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bootstrap-iso .bs-popover-bottom > .arrow::before, .bootstrap-iso .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bootstrap-iso .bs-popover-bottom > .arrow::after, .bootstrap-iso .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bootstrap-iso .bs-popover-bottom .popover-header::before, .bootstrap-iso .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bootstrap-iso .bs-popover-left, .bootstrap-iso .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bootstrap-iso .bs-popover-left > .arrow, .bootstrap-iso .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bootstrap-iso .bs-popover-left > .arrow::before, .bootstrap-iso .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bootstrap-iso .bs-popover-left > .arrow::after, .bootstrap-iso .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.bootstrap-iso .popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.bootstrap-iso .popover-header:empty {
  display: none;
}

.bootstrap-iso .popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.bootstrap-iso .carousel {
  position: relative;
}

.bootstrap-iso .carousel.pointer-event {
  touch-action: pan-y;
}

.bootstrap-iso .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.bootstrap-iso .carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.bootstrap-iso .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .carousel-item {
    transition: none;
  }
}

.bootstrap-iso .carousel-item.active,
.bootstrap-iso .carousel-item-next,
.bootstrap-iso .carousel-item-prev {
  display: block;
}

.bootstrap-iso .carousel-item-next:not(.carousel-item-left),
.bootstrap-iso .active.carousel-item-right {
  transform: translateX(100%);
}

.bootstrap-iso .carousel-item-prev:not(.carousel-item-right),
.bootstrap-iso .active.carousel-item-left {
  transform: translateX(-100%);
}

.bootstrap-iso .carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.bootstrap-iso .carousel-fade .carousel-item.active,
.bootstrap-iso .carousel-fade .carousel-item-next.carousel-item-left,
.bootstrap-iso .carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.bootstrap-iso .carousel-fade .active.carousel-item-left,
.bootstrap-iso .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .carousel-fade .active.carousel-item-left,
  .bootstrap-iso .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.bootstrap-iso .carousel-control-prev,
.bootstrap-iso .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .carousel-control-prev,
  .bootstrap-iso .carousel-control-next {
    transition: none;
  }
}

.bootstrap-iso .carousel-control-prev:hover, .bootstrap-iso .carousel-control-prev:focus,
.bootstrap-iso .carousel-control-next:hover,
.bootstrap-iso .carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.bootstrap-iso .carousel-control-prev {
  left: 0;
}

.bootstrap-iso .carousel-control-next {
  right: 0;
}

.bootstrap-iso .carousel-control-prev-icon,
.bootstrap-iso .carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.bootstrap-iso .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.bootstrap-iso .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.bootstrap-iso .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.bootstrap-iso .carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .carousel-indicators li {
    transition: none;
  }
}

.bootstrap-iso .carousel-indicators .active {
  opacity: 1;
}

.bootstrap-iso .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.bootstrap-iso .spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.bootstrap-iso .spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.bootstrap-iso .spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.bootstrap-iso .spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .bootstrap-iso .spinner-border,
  .bootstrap-iso .spinner-grow {
    animation-duration: 1.5s;
  }
}

.bootstrap-iso .align-baseline {
  vertical-align: baseline !important;
}

.bootstrap-iso .align-top {
  vertical-align: top !important;
}

.bootstrap-iso .align-middle {
  vertical-align: middle !important;
}

.bootstrap-iso .align-bottom {
  vertical-align: bottom !important;
}

.bootstrap-iso .align-text-bottom {
  vertical-align: text-bottom !important;
}

.bootstrap-iso .align-text-top {
  vertical-align: text-top !important;
}

.bootstrap-iso .bg-primary {
  background-color: #007bff !important;
}

.bootstrap-iso a.bg-primary:hover, .bootstrap-iso a.bg-primary:focus,
.bootstrap-iso button.bg-primary:hover,
.bootstrap-iso button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bootstrap-iso .bg-secondary {
  background-color: #6c757d !important;
}

.bootstrap-iso a.bg-secondary:hover, .bootstrap-iso a.bg-secondary:focus,
.bootstrap-iso button.bg-secondary:hover,
.bootstrap-iso button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bootstrap-iso .bg-success {
  background-color: #28a745 !important;
}

.bootstrap-iso a.bg-success:hover, .bootstrap-iso a.bg-success:focus,
.bootstrap-iso button.bg-success:hover,
.bootstrap-iso button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bootstrap-iso .bg-info {
  background-color: #17a2b8 !important;
}

.bootstrap-iso a.bg-info:hover, .bootstrap-iso a.bg-info:focus,
.bootstrap-iso button.bg-info:hover,
.bootstrap-iso button.bg-info:focus {
  background-color: #117a8b !important;
}

.bootstrap-iso .bg-warning {
  background-color: #ffc107 !important;
}

.bootstrap-iso a.bg-warning:hover, .bootstrap-iso a.bg-warning:focus,
.bootstrap-iso button.bg-warning:hover,
.bootstrap-iso button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bootstrap-iso .bg-danger {
  background-color: #dc3545 !important;
}

.bootstrap-iso a.bg-danger:hover, .bootstrap-iso a.bg-danger:focus,
.bootstrap-iso button.bg-danger:hover,
.bootstrap-iso button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bootstrap-iso .bg-light {
  background-color: #f8f9fa !important;
}

.bootstrap-iso a.bg-light:hover, .bootstrap-iso a.bg-light:focus,
.bootstrap-iso button.bg-light:hover,
.bootstrap-iso button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bootstrap-iso .bg-dark {
  background-color: #343a40 !important;
}

.bootstrap-iso a.bg-dark:hover, .bootstrap-iso a.bg-dark:focus,
.bootstrap-iso button.bg-dark:hover,
.bootstrap-iso button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bootstrap-iso .bg-white {
  background-color: #fff !important;
}

.bootstrap-iso .bg-transparent {
  background-color: transparent !important;
}

.bootstrap-iso .border {
  border: 1px solid #dee2e6 !important;
}

.bootstrap-iso .border-top {
  border-top: 1px solid #dee2e6 !important;
}

.bootstrap-iso .border-right {
  border-right: 1px solid #dee2e6 !important;
}

.bootstrap-iso .border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.bootstrap-iso .border-left {
  border-left: 1px solid #dee2e6 !important;
}

.bootstrap-iso .border-0 {
  border: 0 !important;
}

.bootstrap-iso .border-top-0 {
  border-top: 0 !important;
}

.bootstrap-iso .border-right-0 {
  border-right: 0 !important;
}

.bootstrap-iso .border-bottom-0 {
  border-bottom: 0 !important;
}

.bootstrap-iso .border-left-0 {
  border-left: 0 !important;
}

.bootstrap-iso .border-primary {
  border-color: #007bff !important;
}

.bootstrap-iso .border-secondary {
  border-color: #6c757d !important;
}

.bootstrap-iso .border-success {
  border-color: #28a745 !important;
}

.bootstrap-iso .border-info {
  border-color: #17a2b8 !important;
}

.bootstrap-iso .border-warning {
  border-color: #ffc107 !important;
}

.bootstrap-iso .border-danger {
  border-color: #dc3545 !important;
}

.bootstrap-iso .border-light {
  border-color: #f8f9fa !important;
}

.bootstrap-iso .border-dark {
  border-color: #343a40 !important;
}

.bootstrap-iso .border-white {
  border-color: #fff !important;
}

.bootstrap-iso .rounded-sm {
  border-radius: 0.2rem !important;
}

.bootstrap-iso .rounded {
  border-radius: 0.25rem !important;
}

.bootstrap-iso .rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.bootstrap-iso .rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.bootstrap-iso .rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.bootstrap-iso .rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.bootstrap-iso .rounded-lg {
  border-radius: 0.3rem !important;
}

.bootstrap-iso .rounded-circle {
  border-radius: 50% !important;
}

.bootstrap-iso .rounded-pill {
  border-radius: 50rem !important;
}

.bootstrap-iso .rounded-0 {
  border-radius: 0 !important;
}

.bootstrap-iso .clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.bootstrap-iso .d-none {
  display: none !important;
}

.bootstrap-iso .d-inline {
  display: inline !important;
}

.bootstrap-iso .d-inline-block {
  display: inline-block !important;
}

.bootstrap-iso .d-block {
  display: block !important;
}

.bootstrap-iso .d-table {
  display: table !important;
}

.bootstrap-iso .d-table-row {
  display: table-row !important;
}

.bootstrap-iso .d-table-cell {
  display: table-cell !important;
}

.bootstrap-iso .d-flex {
  display: flex !important;
}

.bootstrap-iso .d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .bootstrap-iso .d-sm-none {
    display: none !important;
  }
  .bootstrap-iso .d-sm-inline {
    display: inline !important;
  }
  .bootstrap-iso .d-sm-inline-block {
    display: inline-block !important;
  }
  .bootstrap-iso .d-sm-block {
    display: block !important;
  }
  .bootstrap-iso .d-sm-table {
    display: table !important;
  }
  .bootstrap-iso .d-sm-table-row {
    display: table-row !important;
  }
  .bootstrap-iso .d-sm-table-cell {
    display: table-cell !important;
  }
  .bootstrap-iso .d-sm-flex {
    display: flex !important;
  }
  .bootstrap-iso .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .bootstrap-iso .d-md-none {
    display: none !important;
  }
  .bootstrap-iso .d-md-inline {
    display: inline !important;
  }
  .bootstrap-iso .d-md-inline-block {
    display: inline-block !important;
  }
  .bootstrap-iso .d-md-block {
    display: block !important;
  }
  .bootstrap-iso .d-md-table {
    display: table !important;
  }
  .bootstrap-iso .d-md-table-row {
    display: table-row !important;
  }
  .bootstrap-iso .d-md-table-cell {
    display: table-cell !important;
  }
  .bootstrap-iso .d-md-flex {
    display: flex !important;
  }
  .bootstrap-iso .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .bootstrap-iso .d-lg-none {
    display: none !important;
  }
  .bootstrap-iso .d-lg-inline {
    display: inline !important;
  }
  .bootstrap-iso .d-lg-inline-block {
    display: inline-block !important;
  }
  .bootstrap-iso .d-lg-block {
    display: block !important;
  }
  .bootstrap-iso .d-lg-table {
    display: table !important;
  }
  .bootstrap-iso .d-lg-table-row {
    display: table-row !important;
  }
  .bootstrap-iso .d-lg-table-cell {
    display: table-cell !important;
  }
  .bootstrap-iso .d-lg-flex {
    display: flex !important;
  }
  .bootstrap-iso .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .bootstrap-iso .d-xl-none {
    display: none !important;
  }
  .bootstrap-iso .d-xl-inline {
    display: inline !important;
  }
  .bootstrap-iso .d-xl-inline-block {
    display: inline-block !important;
  }
  .bootstrap-iso .d-xl-block {
    display: block !important;
  }
  .bootstrap-iso .d-xl-table {
    display: table !important;
  }
  .bootstrap-iso .d-xl-table-row {
    display: table-row !important;
  }
  .bootstrap-iso .d-xl-table-cell {
    display: table-cell !important;
  }
  .bootstrap-iso .d-xl-flex {
    display: flex !important;
  }
  .bootstrap-iso .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .bootstrap-iso .d-print-none {
    display: none !important;
  }
  .bootstrap-iso .d-print-inline {
    display: inline !important;
  }
  .bootstrap-iso .d-print-inline-block {
    display: inline-block !important;
  }
  .bootstrap-iso .d-print-block {
    display: block !important;
  }
  .bootstrap-iso .d-print-table {
    display: table !important;
  }
  .bootstrap-iso .d-print-table-row {
    display: table-row !important;
  }
  .bootstrap-iso .d-print-table-cell {
    display: table-cell !important;
  }
  .bootstrap-iso .d-print-flex {
    display: flex !important;
  }
  .bootstrap-iso .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.bootstrap-iso .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.bootstrap-iso .embed-responsive::before {
  display: block;
  content: "";
}

.bootstrap-iso .embed-responsive .embed-responsive-item,
.bootstrap-iso .embed-responsive iframe,
.bootstrap-iso .embed-responsive embed,
.bootstrap-iso .embed-responsive object,
.bootstrap-iso .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.bootstrap-iso .embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.bootstrap-iso .embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.bootstrap-iso .embed-responsive-4by3::before {
  padding-top: 75%;
}

.bootstrap-iso .embed-responsive-1by1::before {
  padding-top: 100%;
}

.bootstrap-iso .flex-row {
  flex-direction: row !important;
}

.bootstrap-iso .flex-column {
  flex-direction: column !important;
}

.bootstrap-iso .flex-row-reverse {
  flex-direction: row-reverse !important;
}

.bootstrap-iso .flex-column-reverse {
  flex-direction: column-reverse !important;
}

.bootstrap-iso .flex-wrap {
  flex-wrap: wrap !important;
}

.bootstrap-iso .flex-nowrap {
  flex-wrap: nowrap !important;
}

.bootstrap-iso .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.bootstrap-iso .flex-fill {
  flex: 1 1 auto !important;
}

.bootstrap-iso .flex-grow-0 {
  flex-grow: 0 !important;
}

.bootstrap-iso .flex-grow-1 {
  flex-grow: 1 !important;
}

.bootstrap-iso .flex-shrink-0 {
  flex-shrink: 0 !important;
}

.bootstrap-iso .flex-shrink-1 {
  flex-shrink: 1 !important;
}

.bootstrap-iso .justify-content-start {
  justify-content: flex-start !important;
}

.bootstrap-iso .justify-content-end {
  justify-content: flex-end !important;
}

.bootstrap-iso .justify-content-center {
  justify-content: center !important;
}

.bootstrap-iso .justify-content-between {
  justify-content: space-between !important;
}

.bootstrap-iso .justify-content-around {
  justify-content: space-around !important;
}

.bootstrap-iso .align-items-start {
  align-items: flex-start !important;
}

.bootstrap-iso .align-items-end {
  align-items: flex-end !important;
}

.bootstrap-iso .align-items-center {
  align-items: center !important;
}

.bootstrap-iso .align-items-baseline {
  align-items: baseline !important;
}

.bootstrap-iso .align-items-stretch {
  align-items: stretch !important;
}

.bootstrap-iso .align-content-start {
  align-content: flex-start !important;
}

.bootstrap-iso .align-content-end {
  align-content: flex-end !important;
}

.bootstrap-iso .align-content-center {
  align-content: center !important;
}

.bootstrap-iso .align-content-between {
  align-content: space-between !important;
}

.bootstrap-iso .align-content-around {
  align-content: space-around !important;
}

.bootstrap-iso .align-content-stretch {
  align-content: stretch !important;
}

.bootstrap-iso .align-self-auto {
  align-self: auto !important;
}

.bootstrap-iso .align-self-start {
  align-self: flex-start !important;
}

.bootstrap-iso .align-self-end {
  align-self: flex-end !important;
}

.bootstrap-iso .align-self-center {
  align-self: center !important;
}

.bootstrap-iso .align-self-baseline {
  align-self: baseline !important;
}

.bootstrap-iso .align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .bootstrap-iso .flex-sm-row {
    flex-direction: row !important;
  }
  .bootstrap-iso .flex-sm-column {
    flex-direction: column !important;
  }
  .bootstrap-iso .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap-iso .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap-iso .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap-iso .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap-iso .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap-iso .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap-iso .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap-iso .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap-iso .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap-iso .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap-iso .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .bootstrap-iso .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .bootstrap-iso .justify-content-sm-center {
    justify-content: center !important;
  }
  .bootstrap-iso .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .bootstrap-iso .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .bootstrap-iso .align-items-sm-start {
    align-items: flex-start !important;
  }
  .bootstrap-iso .align-items-sm-end {
    align-items: flex-end !important;
  }
  .bootstrap-iso .align-items-sm-center {
    align-items: center !important;
  }
  .bootstrap-iso .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .bootstrap-iso .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .bootstrap-iso .align-content-sm-start {
    align-content: flex-start !important;
  }
  .bootstrap-iso .align-content-sm-end {
    align-content: flex-end !important;
  }
  .bootstrap-iso .align-content-sm-center {
    align-content: center !important;
  }
  .bootstrap-iso .align-content-sm-between {
    align-content: space-between !important;
  }
  .bootstrap-iso .align-content-sm-around {
    align-content: space-around !important;
  }
  .bootstrap-iso .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .bootstrap-iso .align-self-sm-auto {
    align-self: auto !important;
  }
  .bootstrap-iso .align-self-sm-start {
    align-self: flex-start !important;
  }
  .bootstrap-iso .align-self-sm-end {
    align-self: flex-end !important;
  }
  .bootstrap-iso .align-self-sm-center {
    align-self: center !important;
  }
  .bootstrap-iso .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .bootstrap-iso .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .bootstrap-iso .flex-md-row {
    flex-direction: row !important;
  }
  .bootstrap-iso .flex-md-column {
    flex-direction: column !important;
  }
  .bootstrap-iso .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap-iso .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap-iso .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap-iso .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap-iso .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap-iso .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap-iso .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap-iso .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap-iso .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap-iso .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap-iso .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .bootstrap-iso .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .bootstrap-iso .justify-content-md-center {
    justify-content: center !important;
  }
  .bootstrap-iso .justify-content-md-between {
    justify-content: space-between !important;
  }
  .bootstrap-iso .justify-content-md-around {
    justify-content: space-around !important;
  }
  .bootstrap-iso .align-items-md-start {
    align-items: flex-start !important;
  }
  .bootstrap-iso .align-items-md-end {
    align-items: flex-end !important;
  }
  .bootstrap-iso .align-items-md-center {
    align-items: center !important;
  }
  .bootstrap-iso .align-items-md-baseline {
    align-items: baseline !important;
  }
  .bootstrap-iso .align-items-md-stretch {
    align-items: stretch !important;
  }
  .bootstrap-iso .align-content-md-start {
    align-content: flex-start !important;
  }
  .bootstrap-iso .align-content-md-end {
    align-content: flex-end !important;
  }
  .bootstrap-iso .align-content-md-center {
    align-content: center !important;
  }
  .bootstrap-iso .align-content-md-between {
    align-content: space-between !important;
  }
  .bootstrap-iso .align-content-md-around {
    align-content: space-around !important;
  }
  .bootstrap-iso .align-content-md-stretch {
    align-content: stretch !important;
  }
  .bootstrap-iso .align-self-md-auto {
    align-self: auto !important;
  }
  .bootstrap-iso .align-self-md-start {
    align-self: flex-start !important;
  }
  .bootstrap-iso .align-self-md-end {
    align-self: flex-end !important;
  }
  .bootstrap-iso .align-self-md-center {
    align-self: center !important;
  }
  .bootstrap-iso .align-self-md-baseline {
    align-self: baseline !important;
  }
  .bootstrap-iso .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .bootstrap-iso .flex-lg-row {
    flex-direction: row !important;
  }
  .bootstrap-iso .flex-lg-column {
    flex-direction: column !important;
  }
  .bootstrap-iso .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap-iso .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap-iso .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap-iso .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap-iso .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap-iso .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap-iso .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap-iso .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap-iso .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap-iso .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap-iso .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .bootstrap-iso .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .bootstrap-iso .justify-content-lg-center {
    justify-content: center !important;
  }
  .bootstrap-iso .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .bootstrap-iso .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .bootstrap-iso .align-items-lg-start {
    align-items: flex-start !important;
  }
  .bootstrap-iso .align-items-lg-end {
    align-items: flex-end !important;
  }
  .bootstrap-iso .align-items-lg-center {
    align-items: center !important;
  }
  .bootstrap-iso .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .bootstrap-iso .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .bootstrap-iso .align-content-lg-start {
    align-content: flex-start !important;
  }
  .bootstrap-iso .align-content-lg-end {
    align-content: flex-end !important;
  }
  .bootstrap-iso .align-content-lg-center {
    align-content: center !important;
  }
  .bootstrap-iso .align-content-lg-between {
    align-content: space-between !important;
  }
  .bootstrap-iso .align-content-lg-around {
    align-content: space-around !important;
  }
  .bootstrap-iso .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .bootstrap-iso .align-self-lg-auto {
    align-self: auto !important;
  }
  .bootstrap-iso .align-self-lg-start {
    align-self: flex-start !important;
  }
  .bootstrap-iso .align-self-lg-end {
    align-self: flex-end !important;
  }
  .bootstrap-iso .align-self-lg-center {
    align-self: center !important;
  }
  .bootstrap-iso .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .bootstrap-iso .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .bootstrap-iso .flex-xl-row {
    flex-direction: row !important;
  }
  .bootstrap-iso .flex-xl-column {
    flex-direction: column !important;
  }
  .bootstrap-iso .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap-iso .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap-iso .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap-iso .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap-iso .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap-iso .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap-iso .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap-iso .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap-iso .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap-iso .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap-iso .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .bootstrap-iso .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .bootstrap-iso .justify-content-xl-center {
    justify-content: center !important;
  }
  .bootstrap-iso .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .bootstrap-iso .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .bootstrap-iso .align-items-xl-start {
    align-items: flex-start !important;
  }
  .bootstrap-iso .align-items-xl-end {
    align-items: flex-end !important;
  }
  .bootstrap-iso .align-items-xl-center {
    align-items: center !important;
  }
  .bootstrap-iso .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .bootstrap-iso .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .bootstrap-iso .align-content-xl-start {
    align-content: flex-start !important;
  }
  .bootstrap-iso .align-content-xl-end {
    align-content: flex-end !important;
  }
  .bootstrap-iso .align-content-xl-center {
    align-content: center !important;
  }
  .bootstrap-iso .align-content-xl-between {
    align-content: space-between !important;
  }
  .bootstrap-iso .align-content-xl-around {
    align-content: space-around !important;
  }
  .bootstrap-iso .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .bootstrap-iso .align-self-xl-auto {
    align-self: auto !important;
  }
  .bootstrap-iso .align-self-xl-start {
    align-self: flex-start !important;
  }
  .bootstrap-iso .align-self-xl-end {
    align-self: flex-end !important;
  }
  .bootstrap-iso .align-self-xl-center {
    align-self: center !important;
  }
  .bootstrap-iso .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .bootstrap-iso .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.bootstrap-iso .float-left {
  float: left !important;
}

.bootstrap-iso .float-right {
  float: right !important;
}

.bootstrap-iso .float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .bootstrap-iso .float-sm-left {
    float: left !important;
  }
  .bootstrap-iso .float-sm-right {
    float: right !important;
  }
  .bootstrap-iso .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .bootstrap-iso .float-md-left {
    float: left !important;
  }
  .bootstrap-iso .float-md-right {
    float: right !important;
  }
  .bootstrap-iso .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .bootstrap-iso .float-lg-left {
    float: left !important;
  }
  .bootstrap-iso .float-lg-right {
    float: right !important;
  }
  .bootstrap-iso .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .bootstrap-iso .float-xl-left {
    float: left !important;
  }
  .bootstrap-iso .float-xl-right {
    float: right !important;
  }
  .bootstrap-iso .float-xl-none {
    float: none !important;
  }
}

.bootstrap-iso .user-select-all {
  user-select: all !important;
}

.bootstrap-iso .user-select-auto {
  user-select: auto !important;
}

.bootstrap-iso .user-select-none {
  user-select: none !important;
}

.bootstrap-iso .overflow-auto {
  overflow: auto !important;
}

.bootstrap-iso .overflow-hidden {
  overflow: hidden !important;
}

.bootstrap-iso .position-static {
  position: static !important;
}

.bootstrap-iso .position-relative {
  position: relative !important;
}

.bootstrap-iso .position-absolute {
  position: absolute !important;
}

.bootstrap-iso .position-fixed {
  position: fixed !important;
}

.bootstrap-iso .position-sticky {
  position: sticky !important;
}

.bootstrap-iso .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.bootstrap-iso .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .bootstrap-iso .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.bootstrap-iso .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.bootstrap-iso .sr-only-focusable:active, .bootstrap-iso .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.bootstrap-iso .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.bootstrap-iso .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.bootstrap-iso .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.bootstrap-iso .shadow-none {
  box-shadow: none !important;
}

.bootstrap-iso .w-25 {
  width: 25% !important;
}

.bootstrap-iso .w-50 {
  width: 50% !important;
}

.bootstrap-iso .w-75 {
  width: 75% !important;
}

.bootstrap-iso .w-100 {
  width: 100% !important;
}

.bootstrap-iso .w-auto {
  width: auto !important;
}

.bootstrap-iso .h-25 {
  height: 25% !important;
}

.bootstrap-iso .h-50 {
  height: 50% !important;
}

.bootstrap-iso .h-75 {
  height: 75% !important;
}

.bootstrap-iso .h-100 {
  height: 100% !important;
}

.bootstrap-iso .h-auto {
  height: auto !important;
}

.bootstrap-iso .mw-100 {
  max-width: 100% !important;
}

.bootstrap-iso .mh-100 {
  max-height: 100% !important;
}

.bootstrap-iso .min-vw-100 {
  min-width: 100vw !important;
}

.bootstrap-iso .min-vh-100 {
  min-height: 100vh !important;
}

.bootstrap-iso .vw-100 {
  width: 100vw !important;
}

.bootstrap-iso .vh-100 {
  height: 100vh !important;
}

.bootstrap-iso .m-0 {
  margin: 0 !important;
}

.bootstrap-iso .mt-0,
.bootstrap-iso .my-0 {
  margin-top: 0 !important;
}

.bootstrap-iso .mr-0,
.bootstrap-iso .mx-0 {
  margin-right: 0 !important;
}

.bootstrap-iso .mb-0,
.bootstrap-iso .my-0 {
  margin-bottom: 0 !important;
}

.bootstrap-iso .ml-0,
.bootstrap-iso .mx-0 {
  margin-left: 0 !important;
}

.bootstrap-iso .m-1 {
  margin: 0.25rem !important;
}

.bootstrap-iso .mt-1,
.bootstrap-iso .my-1 {
  margin-top: 0.25rem !important;
}

.bootstrap-iso .mr-1,
.bootstrap-iso .mx-1 {
  margin-right: 0.25rem !important;
}

.bootstrap-iso .mb-1,
.bootstrap-iso .my-1 {
  margin-bottom: 0.25rem !important;
}

.bootstrap-iso .ml-1,
.bootstrap-iso .mx-1 {
  margin-left: 0.25rem !important;
}

.bootstrap-iso .m-2 {
  margin: 0.5rem !important;
}

.bootstrap-iso .mt-2,
.bootstrap-iso .my-2 {
  margin-top: 0.5rem !important;
}

.bootstrap-iso .mr-2,
.bootstrap-iso .mx-2 {
  margin-right: 0.5rem !important;
}

.bootstrap-iso .mb-2,
.bootstrap-iso .my-2 {
  margin-bottom: 0.5rem !important;
}

.bootstrap-iso .ml-2,
.bootstrap-iso .mx-2 {
  margin-left: 0.5rem !important;
}

.bootstrap-iso .m-3 {
  margin: 1rem !important;
}

.bootstrap-iso .mt-3,
.bootstrap-iso .my-3 {
  margin-top: 1rem !important;
}

.bootstrap-iso .mr-3,
.bootstrap-iso .mx-3 {
  margin-right: 1rem !important;
}

.bootstrap-iso .mb-3,
.bootstrap-iso .my-3 {
  margin-bottom: 1rem !important;
}

.bootstrap-iso .ml-3,
.bootstrap-iso .mx-3 {
  margin-left: 1rem !important;
}

.bootstrap-iso .m-4 {
  margin: 1.5rem !important;
}

.bootstrap-iso .mt-4,
.bootstrap-iso .my-4 {
  margin-top: 1.5rem !important;
}

.bootstrap-iso .mr-4,
.bootstrap-iso .mx-4 {
  margin-right: 1.5rem !important;
}

.bootstrap-iso .mb-4,
.bootstrap-iso .my-4 {
  margin-bottom: 1.5rem !important;
}

.bootstrap-iso .ml-4,
.bootstrap-iso .mx-4 {
  margin-left: 1.5rem !important;
}

.bootstrap-iso .m-5 {
  margin: 3rem !important;
}

.bootstrap-iso .mt-5,
.bootstrap-iso .my-5 {
  margin-top: 3rem !important;
}

.bootstrap-iso .mr-5,
.bootstrap-iso .mx-5 {
  margin-right: 3rem !important;
}

.bootstrap-iso .mb-5,
.bootstrap-iso .my-5 {
  margin-bottom: 3rem !important;
}

.bootstrap-iso .ml-5,
.bootstrap-iso .mx-5 {
  margin-left: 3rem !important;
}

.bootstrap-iso .p-0 {
  padding: 0 !important;
}

.bootstrap-iso .pt-0,
.bootstrap-iso .py-0 {
  padding-top: 0 !important;
}

.bootstrap-iso .pr-0,
.bootstrap-iso .px-0 {
  padding-right: 0 !important;
}

.bootstrap-iso .pb-0,
.bootstrap-iso .py-0 {
  padding-bottom: 0 !important;
}

.bootstrap-iso .pl-0,
.bootstrap-iso .px-0 {
  padding-left: 0 !important;
}

.bootstrap-iso .p-1 {
  padding: 0.25rem !important;
}

.bootstrap-iso .pt-1,
.bootstrap-iso .py-1 {
  padding-top: 0.25rem !important;
}

.bootstrap-iso .pr-1,
.bootstrap-iso .px-1 {
  padding-right: 0.25rem !important;
}

.bootstrap-iso .pb-1,
.bootstrap-iso .py-1 {
  padding-bottom: 0.25rem !important;
}

.bootstrap-iso .pl-1,
.bootstrap-iso .px-1 {
  padding-left: 0.25rem !important;
}

.bootstrap-iso .p-2 {
  padding: 0.5rem !important;
}

.bootstrap-iso .pt-2,
.bootstrap-iso .py-2 {
  padding-top: 0.5rem !important;
}

.bootstrap-iso .pr-2,
.bootstrap-iso .px-2 {
  padding-right: 0.5rem !important;
}

.bootstrap-iso .pb-2,
.bootstrap-iso .py-2 {
  padding-bottom: 0.5rem !important;
}

.bootstrap-iso .pl-2,
.bootstrap-iso .px-2 {
  padding-left: 0.5rem !important;
}

.bootstrap-iso .p-3 {
  padding: 1rem !important;
}

.bootstrap-iso .pt-3,
.bootstrap-iso .py-3 {
  padding-top: 1rem !important;
}

.bootstrap-iso .pr-3,
.bootstrap-iso .px-3 {
  padding-right: 1rem !important;
}

.bootstrap-iso .pb-3,
.bootstrap-iso .py-3 {
  padding-bottom: 1rem !important;
}

.bootstrap-iso .pl-3,
.bootstrap-iso .px-3 {
  padding-left: 1rem !important;
}

.bootstrap-iso .p-4 {
  padding: 1.5rem !important;
}

.bootstrap-iso .pt-4,
.bootstrap-iso .py-4 {
  padding-top: 1.5rem !important;
}

.bootstrap-iso .pr-4,
.bootstrap-iso .px-4 {
  padding-right: 1.5rem !important;
}

.bootstrap-iso .pb-4,
.bootstrap-iso .py-4 {
  padding-bottom: 1.5rem !important;
}

.bootstrap-iso .pl-4,
.bootstrap-iso .px-4 {
  padding-left: 1.5rem !important;
}

.bootstrap-iso .p-5 {
  padding: 3rem !important;
}

.bootstrap-iso .pt-5,
.bootstrap-iso .py-5 {
  padding-top: 3rem !important;
}

.bootstrap-iso .pr-5,
.bootstrap-iso .px-5 {
  padding-right: 3rem !important;
}

.bootstrap-iso .pb-5,
.bootstrap-iso .py-5 {
  padding-bottom: 3rem !important;
}

.bootstrap-iso .pl-5,
.bootstrap-iso .px-5 {
  padding-left: 3rem !important;
}

.bootstrap-iso .m-n1 {
  margin: -0.25rem !important;
}

.bootstrap-iso .mt-n1,
.bootstrap-iso .my-n1 {
  margin-top: -0.25rem !important;
}

.bootstrap-iso .mr-n1,
.bootstrap-iso .mx-n1 {
  margin-right: -0.25rem !important;
}

.bootstrap-iso .mb-n1,
.bootstrap-iso .my-n1 {
  margin-bottom: -0.25rem !important;
}

.bootstrap-iso .ml-n1,
.bootstrap-iso .mx-n1 {
  margin-left: -0.25rem !important;
}

.bootstrap-iso .m-n2 {
  margin: -0.5rem !important;
}

.bootstrap-iso .mt-n2,
.bootstrap-iso .my-n2 {
  margin-top: -0.5rem !important;
}

.bootstrap-iso .mr-n2,
.bootstrap-iso .mx-n2 {
  margin-right: -0.5rem !important;
}

.bootstrap-iso .mb-n2,
.bootstrap-iso .my-n2 {
  margin-bottom: -0.5rem !important;
}

.bootstrap-iso .ml-n2,
.bootstrap-iso .mx-n2 {
  margin-left: -0.5rem !important;
}

.bootstrap-iso .m-n3 {
  margin: -1rem !important;
}

.bootstrap-iso .mt-n3,
.bootstrap-iso .my-n3 {
  margin-top: -1rem !important;
}

.bootstrap-iso .mr-n3,
.bootstrap-iso .mx-n3 {
  margin-right: -1rem !important;
}

.bootstrap-iso .mb-n3,
.bootstrap-iso .my-n3 {
  margin-bottom: -1rem !important;
}

.bootstrap-iso .ml-n3,
.bootstrap-iso .mx-n3 {
  margin-left: -1rem !important;
}

.bootstrap-iso .m-n4 {
  margin: -1.5rem !important;
}

.bootstrap-iso .mt-n4,
.bootstrap-iso .my-n4 {
  margin-top: -1.5rem !important;
}

.bootstrap-iso .mr-n4,
.bootstrap-iso .mx-n4 {
  margin-right: -1.5rem !important;
}

.bootstrap-iso .mb-n4,
.bootstrap-iso .my-n4 {
  margin-bottom: -1.5rem !important;
}

.bootstrap-iso .ml-n4,
.bootstrap-iso .mx-n4 {
  margin-left: -1.5rem !important;
}

.bootstrap-iso .m-n5 {
  margin: -3rem !important;
}

.bootstrap-iso .mt-n5,
.bootstrap-iso .my-n5 {
  margin-top: -3rem !important;
}

.bootstrap-iso .mr-n5,
.bootstrap-iso .mx-n5 {
  margin-right: -3rem !important;
}

.bootstrap-iso .mb-n5,
.bootstrap-iso .my-n5 {
  margin-bottom: -3rem !important;
}

.bootstrap-iso .ml-n5,
.bootstrap-iso .mx-n5 {
  margin-left: -3rem !important;
}

.bootstrap-iso .m-auto {
  margin: auto !important;
}

.bootstrap-iso .mt-auto,
.bootstrap-iso .my-auto {
  margin-top: auto !important;
}

.bootstrap-iso .mr-auto,
.bootstrap-iso .mx-auto {
  margin-right: auto !important;
}

.bootstrap-iso .mb-auto,
.bootstrap-iso .my-auto {
  margin-bottom: auto !important;
}

.bootstrap-iso .ml-auto,
.bootstrap-iso .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .bootstrap-iso .m-sm-0 {
    margin: 0 !important;
  }
  .bootstrap-iso .mt-sm-0,
  .bootstrap-iso .my-sm-0 {
    margin-top: 0 !important;
  }
  .bootstrap-iso .mr-sm-0,
  .bootstrap-iso .mx-sm-0 {
    margin-right: 0 !important;
  }
  .bootstrap-iso .mb-sm-0,
  .bootstrap-iso .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-iso .ml-sm-0,
  .bootstrap-iso .mx-sm-0 {
    margin-left: 0 !important;
  }
  .bootstrap-iso .m-sm-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-iso .mt-sm-1,
  .bootstrap-iso .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-iso .mr-sm-1,
  .bootstrap-iso .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-iso .mb-sm-1,
  .bootstrap-iso .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-iso .ml-sm-1,
  .bootstrap-iso .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-iso .m-sm-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-iso .mt-sm-2,
  .bootstrap-iso .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-iso .mr-sm-2,
  .bootstrap-iso .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-iso .mb-sm-2,
  .bootstrap-iso .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-iso .ml-sm-2,
  .bootstrap-iso .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-iso .m-sm-3 {
    margin: 1rem !important;
  }
  .bootstrap-iso .mt-sm-3,
  .bootstrap-iso .my-sm-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-iso .mr-sm-3,
  .bootstrap-iso .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-iso .mb-sm-3,
  .bootstrap-iso .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-iso .ml-sm-3,
  .bootstrap-iso .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-iso .m-sm-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-iso .mt-sm-4,
  .bootstrap-iso .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-iso .mr-sm-4,
  .bootstrap-iso .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-iso .mb-sm-4,
  .bootstrap-iso .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-iso .ml-sm-4,
  .bootstrap-iso .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-iso .m-sm-5 {
    margin: 3rem !important;
  }
  .bootstrap-iso .mt-sm-5,
  .bootstrap-iso .my-sm-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-iso .mr-sm-5,
  .bootstrap-iso .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-iso .mb-sm-5,
  .bootstrap-iso .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-iso .ml-sm-5,
  .bootstrap-iso .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-iso .p-sm-0 {
    padding: 0 !important;
  }
  .bootstrap-iso .pt-sm-0,
  .bootstrap-iso .py-sm-0 {
    padding-top: 0 !important;
  }
  .bootstrap-iso .pr-sm-0,
  .bootstrap-iso .px-sm-0 {
    padding-right: 0 !important;
  }
  .bootstrap-iso .pb-sm-0,
  .bootstrap-iso .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-iso .pl-sm-0,
  .bootstrap-iso .px-sm-0 {
    padding-left: 0 !important;
  }
  .bootstrap-iso .p-sm-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-iso .pt-sm-1,
  .bootstrap-iso .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-iso .pr-sm-1,
  .bootstrap-iso .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-iso .pb-sm-1,
  .bootstrap-iso .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-iso .pl-sm-1,
  .bootstrap-iso .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-iso .p-sm-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-iso .pt-sm-2,
  .bootstrap-iso .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-iso .pr-sm-2,
  .bootstrap-iso .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-iso .pb-sm-2,
  .bootstrap-iso .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-iso .pl-sm-2,
  .bootstrap-iso .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-iso .p-sm-3 {
    padding: 1rem !important;
  }
  .bootstrap-iso .pt-sm-3,
  .bootstrap-iso .py-sm-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-iso .pr-sm-3,
  .bootstrap-iso .px-sm-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-iso .pb-sm-3,
  .bootstrap-iso .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-iso .pl-sm-3,
  .bootstrap-iso .px-sm-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-iso .p-sm-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-iso .pt-sm-4,
  .bootstrap-iso .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-iso .pr-sm-4,
  .bootstrap-iso .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-iso .pb-sm-4,
  .bootstrap-iso .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-iso .pl-sm-4,
  .bootstrap-iso .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-iso .p-sm-5 {
    padding: 3rem !important;
  }
  .bootstrap-iso .pt-sm-5,
  .bootstrap-iso .py-sm-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-iso .pr-sm-5,
  .bootstrap-iso .px-sm-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-iso .pb-sm-5,
  .bootstrap-iso .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-iso .pl-sm-5,
  .bootstrap-iso .px-sm-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-iso .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap-iso .mt-sm-n1,
  .bootstrap-iso .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap-iso .mr-sm-n1,
  .bootstrap-iso .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap-iso .mb-sm-n1,
  .bootstrap-iso .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap-iso .ml-sm-n1,
  .bootstrap-iso .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap-iso .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap-iso .mt-sm-n2,
  .bootstrap-iso .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap-iso .mr-sm-n2,
  .bootstrap-iso .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap-iso .mb-sm-n2,
  .bootstrap-iso .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap-iso .ml-sm-n2,
  .bootstrap-iso .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap-iso .m-sm-n3 {
    margin: -1rem !important;
  }
  .bootstrap-iso .mt-sm-n3,
  .bootstrap-iso .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap-iso .mr-sm-n3,
  .bootstrap-iso .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap-iso .mb-sm-n3,
  .bootstrap-iso .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap-iso .ml-sm-n3,
  .bootstrap-iso .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap-iso .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap-iso .mt-sm-n4,
  .bootstrap-iso .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap-iso .mr-sm-n4,
  .bootstrap-iso .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap-iso .mb-sm-n4,
  .bootstrap-iso .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap-iso .ml-sm-n4,
  .bootstrap-iso .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap-iso .m-sm-n5 {
    margin: -3rem !important;
  }
  .bootstrap-iso .mt-sm-n5,
  .bootstrap-iso .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap-iso .mr-sm-n5,
  .bootstrap-iso .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap-iso .mb-sm-n5,
  .bootstrap-iso .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap-iso .ml-sm-n5,
  .bootstrap-iso .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap-iso .m-sm-auto {
    margin: auto !important;
  }
  .bootstrap-iso .mt-sm-auto,
  .bootstrap-iso .my-sm-auto {
    margin-top: auto !important;
  }
  .bootstrap-iso .mr-sm-auto,
  .bootstrap-iso .mx-sm-auto {
    margin-right: auto !important;
  }
  .bootstrap-iso .mb-sm-auto,
  .bootstrap-iso .my-sm-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-iso .ml-sm-auto,
  .bootstrap-iso .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .bootstrap-iso .m-md-0 {
    margin: 0 !important;
  }
  .bootstrap-iso .mt-md-0,
  .bootstrap-iso .my-md-0 {
    margin-top: 0 !important;
  }
  .bootstrap-iso .mr-md-0,
  .bootstrap-iso .mx-md-0 {
    margin-right: 0 !important;
  }
  .bootstrap-iso .mb-md-0,
  .bootstrap-iso .my-md-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-iso .ml-md-0,
  .bootstrap-iso .mx-md-0 {
    margin-left: 0 !important;
  }
  .bootstrap-iso .m-md-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-iso .mt-md-1,
  .bootstrap-iso .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-iso .mr-md-1,
  .bootstrap-iso .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-iso .mb-md-1,
  .bootstrap-iso .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-iso .ml-md-1,
  .bootstrap-iso .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-iso .m-md-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-iso .mt-md-2,
  .bootstrap-iso .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-iso .mr-md-2,
  .bootstrap-iso .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-iso .mb-md-2,
  .bootstrap-iso .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-iso .ml-md-2,
  .bootstrap-iso .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-iso .m-md-3 {
    margin: 1rem !important;
  }
  .bootstrap-iso .mt-md-3,
  .bootstrap-iso .my-md-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-iso .mr-md-3,
  .bootstrap-iso .mx-md-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-iso .mb-md-3,
  .bootstrap-iso .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-iso .ml-md-3,
  .bootstrap-iso .mx-md-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-iso .m-md-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-iso .mt-md-4,
  .bootstrap-iso .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-iso .mr-md-4,
  .bootstrap-iso .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-iso .mb-md-4,
  .bootstrap-iso .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-iso .ml-md-4,
  .bootstrap-iso .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-iso .m-md-5 {
    margin: 3rem !important;
  }
  .bootstrap-iso .mt-md-5,
  .bootstrap-iso .my-md-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-iso .mr-md-5,
  .bootstrap-iso .mx-md-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-iso .mb-md-5,
  .bootstrap-iso .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-iso .ml-md-5,
  .bootstrap-iso .mx-md-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-iso .p-md-0 {
    padding: 0 !important;
  }
  .bootstrap-iso .pt-md-0,
  .bootstrap-iso .py-md-0 {
    padding-top: 0 !important;
  }
  .bootstrap-iso .pr-md-0,
  .bootstrap-iso .px-md-0 {
    padding-right: 0 !important;
  }
  .bootstrap-iso .pb-md-0,
  .bootstrap-iso .py-md-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-iso .pl-md-0,
  .bootstrap-iso .px-md-0 {
    padding-left: 0 !important;
  }
  .bootstrap-iso .p-md-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-iso .pt-md-1,
  .bootstrap-iso .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-iso .pr-md-1,
  .bootstrap-iso .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-iso .pb-md-1,
  .bootstrap-iso .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-iso .pl-md-1,
  .bootstrap-iso .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-iso .p-md-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-iso .pt-md-2,
  .bootstrap-iso .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-iso .pr-md-2,
  .bootstrap-iso .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-iso .pb-md-2,
  .bootstrap-iso .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-iso .pl-md-2,
  .bootstrap-iso .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-iso .p-md-3 {
    padding: 1rem !important;
  }
  .bootstrap-iso .pt-md-3,
  .bootstrap-iso .py-md-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-iso .pr-md-3,
  .bootstrap-iso .px-md-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-iso .pb-md-3,
  .bootstrap-iso .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-iso .pl-md-3,
  .bootstrap-iso .px-md-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-iso .p-md-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-iso .pt-md-4,
  .bootstrap-iso .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-iso .pr-md-4,
  .bootstrap-iso .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-iso .pb-md-4,
  .bootstrap-iso .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-iso .pl-md-4,
  .bootstrap-iso .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-iso .p-md-5 {
    padding: 3rem !important;
  }
  .bootstrap-iso .pt-md-5,
  .bootstrap-iso .py-md-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-iso .pr-md-5,
  .bootstrap-iso .px-md-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-iso .pb-md-5,
  .bootstrap-iso .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-iso .pl-md-5,
  .bootstrap-iso .px-md-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-iso .m-md-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap-iso .mt-md-n1,
  .bootstrap-iso .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap-iso .mr-md-n1,
  .bootstrap-iso .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap-iso .mb-md-n1,
  .bootstrap-iso .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap-iso .ml-md-n1,
  .bootstrap-iso .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap-iso .m-md-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap-iso .mt-md-n2,
  .bootstrap-iso .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap-iso .mr-md-n2,
  .bootstrap-iso .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap-iso .mb-md-n2,
  .bootstrap-iso .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap-iso .ml-md-n2,
  .bootstrap-iso .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap-iso .m-md-n3 {
    margin: -1rem !important;
  }
  .bootstrap-iso .mt-md-n3,
  .bootstrap-iso .my-md-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap-iso .mr-md-n3,
  .bootstrap-iso .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap-iso .mb-md-n3,
  .bootstrap-iso .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap-iso .ml-md-n3,
  .bootstrap-iso .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap-iso .m-md-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap-iso .mt-md-n4,
  .bootstrap-iso .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap-iso .mr-md-n4,
  .bootstrap-iso .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap-iso .mb-md-n4,
  .bootstrap-iso .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap-iso .ml-md-n4,
  .bootstrap-iso .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap-iso .m-md-n5 {
    margin: -3rem !important;
  }
  .bootstrap-iso .mt-md-n5,
  .bootstrap-iso .my-md-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap-iso .mr-md-n5,
  .bootstrap-iso .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap-iso .mb-md-n5,
  .bootstrap-iso .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap-iso .ml-md-n5,
  .bootstrap-iso .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap-iso .m-md-auto {
    margin: auto !important;
  }
  .bootstrap-iso .mt-md-auto,
  .bootstrap-iso .my-md-auto {
    margin-top: auto !important;
  }
  .bootstrap-iso .mr-md-auto,
  .bootstrap-iso .mx-md-auto {
    margin-right: auto !important;
  }
  .bootstrap-iso .mb-md-auto,
  .bootstrap-iso .my-md-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-iso .ml-md-auto,
  .bootstrap-iso .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .bootstrap-iso .m-lg-0 {
    margin: 0 !important;
  }
  .bootstrap-iso .mt-lg-0,
  .bootstrap-iso .my-lg-0 {
    margin-top: 0 !important;
  }
  .bootstrap-iso .mr-lg-0,
  .bootstrap-iso .mx-lg-0 {
    margin-right: 0 !important;
  }
  .bootstrap-iso .mb-lg-0,
  .bootstrap-iso .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-iso .ml-lg-0,
  .bootstrap-iso .mx-lg-0 {
    margin-left: 0 !important;
  }
  .bootstrap-iso .m-lg-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-iso .mt-lg-1,
  .bootstrap-iso .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-iso .mr-lg-1,
  .bootstrap-iso .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-iso .mb-lg-1,
  .bootstrap-iso .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-iso .ml-lg-1,
  .bootstrap-iso .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-iso .m-lg-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-iso .mt-lg-2,
  .bootstrap-iso .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-iso .mr-lg-2,
  .bootstrap-iso .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-iso .mb-lg-2,
  .bootstrap-iso .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-iso .ml-lg-2,
  .bootstrap-iso .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-iso .m-lg-3 {
    margin: 1rem !important;
  }
  .bootstrap-iso .mt-lg-3,
  .bootstrap-iso .my-lg-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-iso .mr-lg-3,
  .bootstrap-iso .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-iso .mb-lg-3,
  .bootstrap-iso .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-iso .ml-lg-3,
  .bootstrap-iso .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-iso .m-lg-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-iso .mt-lg-4,
  .bootstrap-iso .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-iso .mr-lg-4,
  .bootstrap-iso .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-iso .mb-lg-4,
  .bootstrap-iso .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-iso .ml-lg-4,
  .bootstrap-iso .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-iso .m-lg-5 {
    margin: 3rem !important;
  }
  .bootstrap-iso .mt-lg-5,
  .bootstrap-iso .my-lg-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-iso .mr-lg-5,
  .bootstrap-iso .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-iso .mb-lg-5,
  .bootstrap-iso .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-iso .ml-lg-5,
  .bootstrap-iso .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-iso .p-lg-0 {
    padding: 0 !important;
  }
  .bootstrap-iso .pt-lg-0,
  .bootstrap-iso .py-lg-0 {
    padding-top: 0 !important;
  }
  .bootstrap-iso .pr-lg-0,
  .bootstrap-iso .px-lg-0 {
    padding-right: 0 !important;
  }
  .bootstrap-iso .pb-lg-0,
  .bootstrap-iso .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-iso .pl-lg-0,
  .bootstrap-iso .px-lg-0 {
    padding-left: 0 !important;
  }
  .bootstrap-iso .p-lg-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-iso .pt-lg-1,
  .bootstrap-iso .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-iso .pr-lg-1,
  .bootstrap-iso .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-iso .pb-lg-1,
  .bootstrap-iso .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-iso .pl-lg-1,
  .bootstrap-iso .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-iso .p-lg-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-iso .pt-lg-2,
  .bootstrap-iso .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-iso .pr-lg-2,
  .bootstrap-iso .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-iso .pb-lg-2,
  .bootstrap-iso .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-iso .pl-lg-2,
  .bootstrap-iso .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-iso .p-lg-3 {
    padding: 1rem !important;
  }
  .bootstrap-iso .pt-lg-3,
  .bootstrap-iso .py-lg-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-iso .pr-lg-3,
  .bootstrap-iso .px-lg-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-iso .pb-lg-3,
  .bootstrap-iso .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-iso .pl-lg-3,
  .bootstrap-iso .px-lg-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-iso .p-lg-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-iso .pt-lg-4,
  .bootstrap-iso .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-iso .pr-lg-4,
  .bootstrap-iso .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-iso .pb-lg-4,
  .bootstrap-iso .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-iso .pl-lg-4,
  .bootstrap-iso .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-iso .p-lg-5 {
    padding: 3rem !important;
  }
  .bootstrap-iso .pt-lg-5,
  .bootstrap-iso .py-lg-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-iso .pr-lg-5,
  .bootstrap-iso .px-lg-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-iso .pb-lg-5,
  .bootstrap-iso .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-iso .pl-lg-5,
  .bootstrap-iso .px-lg-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-iso .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap-iso .mt-lg-n1,
  .bootstrap-iso .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap-iso .mr-lg-n1,
  .bootstrap-iso .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap-iso .mb-lg-n1,
  .bootstrap-iso .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap-iso .ml-lg-n1,
  .bootstrap-iso .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap-iso .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap-iso .mt-lg-n2,
  .bootstrap-iso .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap-iso .mr-lg-n2,
  .bootstrap-iso .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap-iso .mb-lg-n2,
  .bootstrap-iso .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap-iso .ml-lg-n2,
  .bootstrap-iso .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap-iso .m-lg-n3 {
    margin: -1rem !important;
  }
  .bootstrap-iso .mt-lg-n3,
  .bootstrap-iso .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap-iso .mr-lg-n3,
  .bootstrap-iso .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap-iso .mb-lg-n3,
  .bootstrap-iso .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap-iso .ml-lg-n3,
  .bootstrap-iso .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap-iso .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap-iso .mt-lg-n4,
  .bootstrap-iso .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap-iso .mr-lg-n4,
  .bootstrap-iso .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap-iso .mb-lg-n4,
  .bootstrap-iso .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap-iso .ml-lg-n4,
  .bootstrap-iso .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap-iso .m-lg-n5 {
    margin: -3rem !important;
  }
  .bootstrap-iso .mt-lg-n5,
  .bootstrap-iso .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap-iso .mr-lg-n5,
  .bootstrap-iso .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap-iso .mb-lg-n5,
  .bootstrap-iso .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap-iso .ml-lg-n5,
  .bootstrap-iso .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap-iso .m-lg-auto {
    margin: auto !important;
  }
  .bootstrap-iso .mt-lg-auto,
  .bootstrap-iso .my-lg-auto {
    margin-top: auto !important;
  }
  .bootstrap-iso .mr-lg-auto,
  .bootstrap-iso .mx-lg-auto {
    margin-right: auto !important;
  }
  .bootstrap-iso .mb-lg-auto,
  .bootstrap-iso .my-lg-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-iso .ml-lg-auto,
  .bootstrap-iso .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .bootstrap-iso .m-xl-0 {
    margin: 0 !important;
  }
  .bootstrap-iso .mt-xl-0,
  .bootstrap-iso .my-xl-0 {
    margin-top: 0 !important;
  }
  .bootstrap-iso .mr-xl-0,
  .bootstrap-iso .mx-xl-0 {
    margin-right: 0 !important;
  }
  .bootstrap-iso .mb-xl-0,
  .bootstrap-iso .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap-iso .ml-xl-0,
  .bootstrap-iso .mx-xl-0 {
    margin-left: 0 !important;
  }
  .bootstrap-iso .m-xl-1 {
    margin: 0.25rem !important;
  }
  .bootstrap-iso .mt-xl-1,
  .bootstrap-iso .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap-iso .mr-xl-1,
  .bootstrap-iso .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap-iso .mb-xl-1,
  .bootstrap-iso .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap-iso .ml-xl-1,
  .bootstrap-iso .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap-iso .m-xl-2 {
    margin: 0.5rem !important;
  }
  .bootstrap-iso .mt-xl-2,
  .bootstrap-iso .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap-iso .mr-xl-2,
  .bootstrap-iso .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap-iso .mb-xl-2,
  .bootstrap-iso .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap-iso .ml-xl-2,
  .bootstrap-iso .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap-iso .m-xl-3 {
    margin: 1rem !important;
  }
  .bootstrap-iso .mt-xl-3,
  .bootstrap-iso .my-xl-3 {
    margin-top: 1rem !important;
  }
  .bootstrap-iso .mr-xl-3,
  .bootstrap-iso .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .bootstrap-iso .mb-xl-3,
  .bootstrap-iso .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap-iso .ml-xl-3,
  .bootstrap-iso .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .bootstrap-iso .m-xl-4 {
    margin: 1.5rem !important;
  }
  .bootstrap-iso .mt-xl-4,
  .bootstrap-iso .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap-iso .mr-xl-4,
  .bootstrap-iso .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap-iso .mb-xl-4,
  .bootstrap-iso .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap-iso .ml-xl-4,
  .bootstrap-iso .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap-iso .m-xl-5 {
    margin: 3rem !important;
  }
  .bootstrap-iso .mt-xl-5,
  .bootstrap-iso .my-xl-5 {
    margin-top: 3rem !important;
  }
  .bootstrap-iso .mr-xl-5,
  .bootstrap-iso .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .bootstrap-iso .mb-xl-5,
  .bootstrap-iso .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap-iso .ml-xl-5,
  .bootstrap-iso .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .bootstrap-iso .p-xl-0 {
    padding: 0 !important;
  }
  .bootstrap-iso .pt-xl-0,
  .bootstrap-iso .py-xl-0 {
    padding-top: 0 !important;
  }
  .bootstrap-iso .pr-xl-0,
  .bootstrap-iso .px-xl-0 {
    padding-right: 0 !important;
  }
  .bootstrap-iso .pb-xl-0,
  .bootstrap-iso .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap-iso .pl-xl-0,
  .bootstrap-iso .px-xl-0 {
    padding-left: 0 !important;
  }
  .bootstrap-iso .p-xl-1 {
    padding: 0.25rem !important;
  }
  .bootstrap-iso .pt-xl-1,
  .bootstrap-iso .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap-iso .pr-xl-1,
  .bootstrap-iso .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap-iso .pb-xl-1,
  .bootstrap-iso .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap-iso .pl-xl-1,
  .bootstrap-iso .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap-iso .p-xl-2 {
    padding: 0.5rem !important;
  }
  .bootstrap-iso .pt-xl-2,
  .bootstrap-iso .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap-iso .pr-xl-2,
  .bootstrap-iso .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap-iso .pb-xl-2,
  .bootstrap-iso .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap-iso .pl-xl-2,
  .bootstrap-iso .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap-iso .p-xl-3 {
    padding: 1rem !important;
  }
  .bootstrap-iso .pt-xl-3,
  .bootstrap-iso .py-xl-3 {
    padding-top: 1rem !important;
  }
  .bootstrap-iso .pr-xl-3,
  .bootstrap-iso .px-xl-3 {
    padding-right: 1rem !important;
  }
  .bootstrap-iso .pb-xl-3,
  .bootstrap-iso .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap-iso .pl-xl-3,
  .bootstrap-iso .px-xl-3 {
    padding-left: 1rem !important;
  }
  .bootstrap-iso .p-xl-4 {
    padding: 1.5rem !important;
  }
  .bootstrap-iso .pt-xl-4,
  .bootstrap-iso .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap-iso .pr-xl-4,
  .bootstrap-iso .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap-iso .pb-xl-4,
  .bootstrap-iso .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap-iso .pl-xl-4,
  .bootstrap-iso .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap-iso .p-xl-5 {
    padding: 3rem !important;
  }
  .bootstrap-iso .pt-xl-5,
  .bootstrap-iso .py-xl-5 {
    padding-top: 3rem !important;
  }
  .bootstrap-iso .pr-xl-5,
  .bootstrap-iso .px-xl-5 {
    padding-right: 3rem !important;
  }
  .bootstrap-iso .pb-xl-5,
  .bootstrap-iso .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap-iso .pl-xl-5,
  .bootstrap-iso .px-xl-5 {
    padding-left: 3rem !important;
  }
  .bootstrap-iso .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap-iso .mt-xl-n1,
  .bootstrap-iso .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap-iso .mr-xl-n1,
  .bootstrap-iso .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap-iso .mb-xl-n1,
  .bootstrap-iso .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap-iso .ml-xl-n1,
  .bootstrap-iso .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap-iso .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap-iso .mt-xl-n2,
  .bootstrap-iso .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap-iso .mr-xl-n2,
  .bootstrap-iso .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap-iso .mb-xl-n2,
  .bootstrap-iso .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap-iso .ml-xl-n2,
  .bootstrap-iso .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap-iso .m-xl-n3 {
    margin: -1rem !important;
  }
  .bootstrap-iso .mt-xl-n3,
  .bootstrap-iso .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap-iso .mr-xl-n3,
  .bootstrap-iso .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap-iso .mb-xl-n3,
  .bootstrap-iso .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap-iso .ml-xl-n3,
  .bootstrap-iso .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap-iso .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap-iso .mt-xl-n4,
  .bootstrap-iso .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap-iso .mr-xl-n4,
  .bootstrap-iso .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap-iso .mb-xl-n4,
  .bootstrap-iso .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap-iso .ml-xl-n4,
  .bootstrap-iso .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap-iso .m-xl-n5 {
    margin: -3rem !important;
  }
  .bootstrap-iso .mt-xl-n5,
  .bootstrap-iso .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap-iso .mr-xl-n5,
  .bootstrap-iso .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap-iso .mb-xl-n5,
  .bootstrap-iso .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap-iso .ml-xl-n5,
  .bootstrap-iso .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap-iso .m-xl-auto {
    margin: auto !important;
  }
  .bootstrap-iso .mt-xl-auto,
  .bootstrap-iso .my-xl-auto {
    margin-top: auto !important;
  }
  .bootstrap-iso .mr-xl-auto,
  .bootstrap-iso .mx-xl-auto {
    margin-right: auto !important;
  }
  .bootstrap-iso .mb-xl-auto,
  .bootstrap-iso .my-xl-auto {
    margin-bottom: auto !important;
  }
  .bootstrap-iso .ml-xl-auto,
  .bootstrap-iso .mx-xl-auto {
    margin-left: auto !important;
  }
}

.bootstrap-iso .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.bootstrap-iso .text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.bootstrap-iso .text-justify {
  text-align: justify !important;
}

.bootstrap-iso .text-wrap {
  white-space: normal !important;
}

.bootstrap-iso .text-nowrap {
  white-space: nowrap !important;
}

.bootstrap-iso .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bootstrap-iso .text-left {
  text-align: left !important;
}

.bootstrap-iso .text-right {
  text-align: right !important;
}

.bootstrap-iso .text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .bootstrap-iso .text-sm-left {
    text-align: left !important;
  }
  .bootstrap-iso .text-sm-right {
    text-align: right !important;
  }
  .bootstrap-iso .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .bootstrap-iso .text-md-left {
    text-align: left !important;
  }
  .bootstrap-iso .text-md-right {
    text-align: right !important;
  }
  .bootstrap-iso .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .bootstrap-iso .text-lg-left {
    text-align: left !important;
  }
  .bootstrap-iso .text-lg-right {
    text-align: right !important;
  }
  .bootstrap-iso .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .bootstrap-iso .text-xl-left {
    text-align: left !important;
  }
  .bootstrap-iso .text-xl-right {
    text-align: right !important;
  }
  .bootstrap-iso .text-xl-center {
    text-align: center !important;
  }
}

.bootstrap-iso .text-lowercase {
  text-transform: lowercase !important;
}

.bootstrap-iso .text-uppercase {
  text-transform: uppercase !important;
}

.bootstrap-iso .text-capitalize {
  text-transform: capitalize !important;
}

.bootstrap-iso .font-weight-light {
  font-weight: 300 !important;
}

.bootstrap-iso .font-weight-lighter {
  font-weight: lighter !important;
}

.bootstrap-iso .font-weight-normal {
  font-weight: 400 !important;
}

.bootstrap-iso .font-weight-bold {
  font-weight: 700 !important;
}

.bootstrap-iso .font-weight-bolder {
  font-weight: bolder !important;
}

.bootstrap-iso .font-italic {
  font-style: italic !important;
}

.bootstrap-iso .text-white {
  color: #fff !important;
}

.bootstrap-iso .text-primary {
  color: #007bff !important;
}

.bootstrap-iso a.text-primary:hover, .bootstrap-iso a.text-primary:focus {
  color: #0056b3 !important;
}

.bootstrap-iso .text-secondary {
  color: #6c757d !important;
}

.bootstrap-iso a.text-secondary:hover, .bootstrap-iso a.text-secondary:focus {
  color: #494f54 !important;
}

.bootstrap-iso .text-success {
  color: #28a745 !important;
}

.bootstrap-iso a.text-success:hover, .bootstrap-iso a.text-success:focus {
  color: #19692c !important;
}

.bootstrap-iso .text-info {
  color: #17a2b8 !important;
}

.bootstrap-iso a.text-info:hover, .bootstrap-iso a.text-info:focus {
  color: #0f6674 !important;
}

.bootstrap-iso .text-warning {
  color: #ffc107 !important;
}

.bootstrap-iso a.text-warning:hover, .bootstrap-iso a.text-warning:focus {
  color: #ba8b00 !important;
}

.bootstrap-iso .text-danger {
  color: #dc3545 !important;
}

.bootstrap-iso a.text-danger:hover, .bootstrap-iso a.text-danger:focus {
  color: #a71d2a !important;
}

.bootstrap-iso .text-light {
  color: #f8f9fa !important;
}

.bootstrap-iso a.text-light:hover, .bootstrap-iso a.text-light:focus {
  color: #cbd3da !important;
}

.bootstrap-iso .text-dark {
  color: #343a40 !important;
}

.bootstrap-iso a.text-dark:hover, .bootstrap-iso a.text-dark:focus {
  color: #121416 !important;
}

.bootstrap-iso .text-body {
  color: #212529 !important;
}

.bootstrap-iso .text-muted {
  color: #6c757d !important;
}

.bootstrap-iso .text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.bootstrap-iso .text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.bootstrap-iso .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.bootstrap-iso .text-decoration-none {
  text-decoration: none !important;
}

.bootstrap-iso .text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.bootstrap-iso .text-reset {
  color: inherit !important;
}

.bootstrap-iso .visible {
  visibility: visible !important;
}

.bootstrap-iso .invisible {
  visibility: hidden !important;
}

@media print {
  .bootstrap-iso *,
  .bootstrap-iso *::before,
  .bootstrap-iso *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  .bootstrap-iso a:not(.btn) {
    text-decoration: underline;
  }
  .bootstrap-iso abbr[title]::after {
    content: " (" attr(title) ")";
  }
  .bootstrap-iso pre {
    white-space: pre-wrap !important;
  }
  .bootstrap-iso pre,
  .bootstrap-iso blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  .bootstrap-iso tr,
  .bootstrap-iso img {
    page-break-inside: avoid;
  }
  .bootstrap-iso p,
  .bootstrap-iso h2,
  .bootstrap-iso h3 {
    orphans: 3;
    widows: 3;
  }
  .bootstrap-iso h2,
  .bootstrap-iso h3 {
    page-break-after: avoid;
  }
  @page {
    .bootstrap-iso {
      size: a3;
    }
  }
  .bootstrap-iso body {
    min-width: 992px !important;
  }
  .bootstrap-iso .container {
    min-width: 992px !important;
  }
  .bootstrap-iso .navbar {
    display: none;
  }
  .bootstrap-iso .badge {
    border: 1px solid #000;
  }
  .bootstrap-iso .table {
    border-collapse: collapse !important;
  }
  .bootstrap-iso .table td,
  .bootstrap-iso .table th {
    background-color: #fff !important;
  }
  .bootstrap-iso .table-bordered th,
  .bootstrap-iso .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .bootstrap-iso .table-dark {
    color: inherit;
  }
  .bootstrap-iso .table-dark th,
  .bootstrap-iso .table-dark td,
  .bootstrap-iso .table-dark thead th,
  .bootstrap-iso .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .bootstrap-iso .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/*# sourceMappingURL=bootstrap.css.map */